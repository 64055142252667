import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { EditMenuComponent } from '../../modals/edit-menu/edit-menu.component';
import {IMenu, IMenuGroup, IMenuGroupTableData, MenuFile} from '../../../interfaces/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { state, style, trigger } from '@angular/animations';
import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-menu-table',
  templateUrl: './menu-table.component.html',
  styleUrls: ['./menu-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
    ]),
  ],
})
export class MenuTableComponent implements OnInit, AfterViewInit {
  private originalMenus!: IMenuGroup[];

  public searchTerm$ = new BehaviorSubject<string>('');
  public menusData: IMenuGroupTableData[] = [];
  public edit = false;
  public displayedColumns = ['name', 'language', 'file', 'icon', 'hidden', 'edit'];
  public expandedElements: number[] = [];
  public dataSource = new MatTableDataSource<IMenuGroupTableData>();

  @Input() set menus(menus: IMenuGroup[]) {
    this.originalMenus = menus;
    this.menusData = [];

    menus.forEach(menu => {
      if (menu.translation && Array.isArray(menu.translation) && menu.translation.length) {
        this.menusData = [...this.menusData, {...menu, translation: new MatTableDataSource(menu.translation)}];
      } else {
        this.menusData = [...this.menusData, menu];
      }
    });
    this.dataSource = new MatTableDataSource(this.menusData);
  }

  @Input() isEdit = false;
  @Input() restaurantId!: number;
  @Input() isMainMenu = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private readonly activateRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data: IMenuGroupTableData, searchParam: string) => {
      return this.createFilter(data, searchParam);
    };

    this.activateRoute.queryParams.pipe(
      filter(params => params.s !== undefined),
      tap(params => {
        const searchParam = params.s || '';
        this.searchTerm$.next(searchParam);
      }),
      untilDestroyed(this)
    ).subscribe(() => this.applyFilter(this.searchTerm$.getValue()));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
    console.log(this.searchTerm$.getValue());
    this.dataSource.filter = searchTerm;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleRow(element: IMenuGroupTableData): void {
    if (element.translation && (element.translation as MatTableDataSource<IMenu>).data?.length) {
      if (this.expandedElements.includes(element.id)) {
        this.expandedElements = this.expandedElements.filter(id => id !== element.id);
      } else {
        this.expandedElements.push(element.id);
      }
    }
  }

  isExpanded(element: IMenuGroupTableData): boolean {
    return this.expandedElements.includes(element.id);
  }

  openEdit(id: number): void {
    const menuGroup = this.originalMenus.find(menu => menu.id === id);

    if (menuGroup?.ownerType !== 'RESTAURANT') {
      return;
    }

    this.dialog.open(EditMenuComponent, {
      data: {
        restaurantId: this.restaurantId,
        isMainMenu: this.isMainMenu,
        menusGroup: menuGroup
          ? menuGroup.translation?.length > 0
            ? [menuGroup, ...menuGroup.translation]
            : [menuGroup]
          : []
      }
    });
  }

  createFilter(data: IMenuGroupTableData, searchTerm: string): boolean {
    const querySanitized = searchTerm.trim().toLowerCase();

    const matchesString = (value?: string | null): boolean =>
        value?.toLowerCase().includes(querySanitized) ?? false;

    const matchesFileName = (file?: MenuFile | null): boolean =>
        file ? matchesString(file.name) : false;

    const matchesTranslation = (): boolean =>
        data.translation instanceof MatTableDataSource &&
        data.translation.data.some((translation) =>
            [translation.name, translation.language, translation.file?.name].some(matchesString) ||
            (querySanitized === 'hidden' && translation.hidden) ||
            (querySanitized === 'no icon' && !translation.icon)
        );

    return (
        matchesString(data.name) ||
        matchesString(data.language) ||
        matchesFileName(data.file) ||
        (querySanitized === 'hidden' && data.hidden) ||
        (querySanitized === 'no icon' && !data.icon) ||
        matchesTranslation()
    );
  }

}
