import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  IChainMenuGroup,
  IChainMenuGroupTableData,
  IMenu,
  IMenuGroupTableData, MenuFile,
} from '../../../interfaces/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { state, style, trigger } from '@angular/animations';
import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { EditChainMenuComponent } from '../../modals/edit-chain-menu/edit-chain-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-chain-menu-table',
  templateUrl: './chain-menu-table.component.html',
  styleUrls: ['./chain-menu-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
    ]),
  ],
})
export class ChainMenuTableComponent implements OnInit, AfterViewInit {
  private originalMenus!: IChainMenuGroup[];

  public searchTerm$ = new BehaviorSubject<string>('');
  public menusData: IChainMenuGroupTableData[] = [];
  public edit = false;
  public displayedColumns = ['name', 'language', 'file', 'icon', 'hidden', 'restaurants', 'edit'];
  public expandedElements: number[] = [];
  public dataSource = new MatTableDataSource<IChainMenuGroupTableData>();
  public restaurantNames: { [id: number]: string } = {};

  @Input() set menus(menus: IChainMenuGroup[]) {
    this.originalMenus = menus;
    this.menusData = [];
    menus.forEach(menu => {
      if (menu.translation && Array.isArray(menu.translation) && menu.translation.length) {
        this.menusData = [
          ...this.menusData,
          {
            ...menu,
            translation: new MatTableDataSource(menu.translation)
          }
        ];
      } else {
        this.menusData = [...this.menusData, menu];
      }
    });
    this.dataSource = new MatTableDataSource(this.menusData);
  }

  @Input() isEdit = false;
  @Input() chainId!: number;
  @Input() isMainMenu = true;
  @Input() restaurants!: IRestaurantInfo[];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private activateRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSource.filterPredicate = (data: IChainMenuGroupTableData, searchParam: string) => {
      return this.createFilter(data, searchParam);
    };

    this.restaurantNames = this.restaurants.reduce(
      (acc: { [id: number]: string }, r) => {
        acc[r.id] = r.name;
        return acc;
      },
      {},
    );

    this.activateRoute.queryParams.pipe(
      filter(params => params.s !== undefined),
      tap(params => {
        const searchParam = params.s || '';
        this.searchTerm$.next(searchParam);
      }),
      untilDestroyed(this)
    ).subscribe(() => this.applyFilter(this.searchTerm$.getValue()));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public applyFilter(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
    this.dataSource.filter = searchTerm;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public toggleRow(element: IMenuGroupTableData): void {
    if (element.translation && (element.translation as MatTableDataSource<IMenu>).data?.length) {
      if (this.expandedElements.includes(element.id)) {
        this.expandedElements = this.expandedElements.filter(id => id !== element.id);
      } else {
        this.expandedElements.push(element.id);
      }
    }
  }

  public isExpanded(element: IMenuGroupTableData): boolean {
    return this.expandedElements.includes(element.id);
  }

  public openEdit(id: number): void {
    const menuGroup = this.originalMenus.find(menu => menu.id === id);
    this.dialog.open(EditChainMenuComponent, {
      data: {
        chainId: this.chainId,
        isMainMenu: true,
        restaurants: this.restaurants,
        menusGroup: menuGroup
          ? menuGroup.translation?.length > 0
            ? [menuGroup, ...menuGroup.translation]
            : [menuGroup]
          : []
      }
    });
  }


  restaurantFilter(restaurant: IRestaurantInfo, query: string): boolean {
    return (
        restaurant.name.toLowerCase().includes(query) ||
        restaurant.email.toLowerCase().includes(query)
    );
  }

  createFilter(data: IChainMenuGroupTableData, searchTerm: string): boolean {
    const querySanitized = searchTerm.trim().toLowerCase();

    const matchesString = (value?: string | null): boolean =>
        value?.toString().toLowerCase().includes(querySanitized) ?? false;

    const matchesFileName = (file: MenuFile | null): boolean =>
        file ? matchesString(file.name) : false;

    const matchesTranslation = (): boolean =>
        data.translation instanceof MatTableDataSource &&
        data.translation.data.some((translation) =>
            [translation.name, translation.language, translation.file?.name]
                .some(matchesString) ||
            (querySanitized === 'hidden' && translation.hidden) ||
            (querySanitized === 'no icon' && !translation.icon)
        );

    const matchesRestaurants = (): boolean => {
      if (data.attachedToAll) {
        return this.restaurants.some((r) => this.restaurantFilter(r, querySanitized));
      }

      return data.attachedRestaurantIds?.some((id) => {
        const restaurant = this.restaurants.find((r) => r.id === id);
        return restaurant ? this.restaurantFilter(restaurant, querySanitized) : false;
      }) ?? false;
    };

    const matchesNoRestaurants = (): boolean =>
        querySanitized === 'no restaurants' &&
        !data.attachedToAll &&
        (!data.attachedRestaurantIds || data.attachedRestaurantIds.length === 0 || data.attachedRestaurantIds[0] === null);

    return (
        matchesString(data.name) ||
        matchesString(data.language) ||
        matchesFileName(data.file) ||
        (querySanitized === 'hidden' && data.hidden) ||
        (querySanitized === 'no icon' && !data.icon) ||
        matchesNoRestaurants() ||
        matchesRestaurants() ||
        matchesTranslation()
    );
  }

}
