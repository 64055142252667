import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEditTable, ITable } from '../../interfaces/table';
import { AnyIntegration, AnyIntegrationNew, IFeature } from '../../interfaces/restaurant';
import { Period, NewPeriod } from '../../interfaces/period';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  constructor(
    private http: HttpClient
  ) {
  }

  public addTable(id: number, name: string, widgetCode: string, description?: string, qrCodeFileId?: string,  ): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${id}/tables`, {name, widgetCode, description, qrCodeFileId});
  }

  public linkNewDeviceToTable(restaurantId: number, tableId: number, widgetCode: string): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${restaurantId}/tables/${tableId}/link-device`, { widgetCode });
  }

  public unlinkDevice(restaurantId: number, tableId: number): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${restaurantId}/tables/${tableId}/unlink-device`, {});
  }

  public editTable(restaurantId: number, tableId: number, table: {
    name?: string,
    qrCodeFileId?: string,
    description?: string
  }): Observable<ITable> {
    return this.http.patch<ITable>(`/restaurants/${restaurantId}/tables/${tableId}`, table)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public getQrCode(file: any, tableId: number, restaurantId: number): Observable<any> {
    const dataQr: FormData = new FormData();
    dataQr.append('QRCodeImage', file, file.name);
    return this.http.patch(`/restaurants/${restaurantId}/tables/${tableId}`, dataQr);
  }

  public addWaiter({id, name, email, password, tableIds, role = 'WAITER'}:
    { id: string, name: string, email: string, password: string, tableIds?: string[],  role?: string }): Observable<any> {
    return this.http.post(`/restaurants/${id}/staff`, {name, email, password, tableIds,  role});
  }

  public getAllTables(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/tables`)
      .pipe(
        catchError((err) => {
          throw err;
          return [];
        })
      );
  }

  public getAllWaiters(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/staff/waiters/`);
  }

  public removeWaiter(restaurantId: number, staffId: number): Observable<void> {
    return this.http.delete<void>(`/restaurants/${restaurantId}/staff/${staffId}`);
  }

  public getWaiterById(id: number, staffId: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/staff/${staffId}`);
  }

  public editWaiter({id, name, email, password, tableIdsToAttach, tableIdsToDetach, idStaff, role = 'WAITER'}: IEditTable): Observable<any>{
    return this.http.patch(`/restaurants/${id}/staff`, {
      id: idStaff,
      name,
      email,
      password,
      tableIdsToAttach,
      tableIdsToDetach,
      role
    });
  }

  public addIntegration(id: number, {provider, credentials}: AnyIntegrationNew): Observable<any> {
    return this.http.post(`/restaurants/${id}/integrations`, {provider, credentials});
  }

  public getIntegrationById(id: number, integrationId: string): Observable<any> {
    return this.http.get(`/restaurants/${id}/integrations/${integrationId}`);
  }

  public getAllIntegration(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/integrations`);
  }

  public editIntegration(restaurantId: number, {id, provider, credentials}: AnyIntegration): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}/integrations/${id}`, {
      provider, credentials
    });
  }
  public removeIntegration(id: number, integrationId: string): Observable<void>{
    return this.http.delete<void>(`/restaurants/${id}/integrations/${integrationId}`);
  }

  public configureCollWaiter(restaurantId: number, {
      withCallWaiter,
      withCallWaiterToPay,
      withCallWaiterToRepeat
    }: IFeature
  ): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}`, {withCallWaiter, withCallWaiterToPay, withCallWaiterToRepeat});
  }

  public uploadPdf(file: any, id: number): Observable<any> {
    const testData: FormData = new FormData();
    testData.append('menu', file, file.name);
    return this.http.post(`/restaurants/menu/upload/${id}`, testData, {
      reportProgress: true
    });
  }

  public getAllHappyHoursPeriods(id: number): Observable<Period[]> {
    return this.http.get<Period[]>(`/restaurants/${id}/happy-menu-periods`);
  }

  public createHappyHoursPeriod(id: number, period: NewPeriod): Observable<any> {
    return this.http.post(`/restaurants/${id}/happy-menu-periods`, {
      startDay: period.startDay,
      startTime: period.startTime,
      endDay: period.endDay,
      endTime: period.endTime
    });
  }

  public updateHappyHoursPeriod(restaurantId: number, id: number, period: Period): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}/happy-menu-periods/${id}`, {
      startDay: period.startDay,
      startTime: period.startTime,
      endDay: period.endDay,
      endTime: period.endTime
    });
  }

  public deleteHappyHoursPeriod(restaurantId: number, id: number): Observable<any> {
    return this.http.delete(`/restaurants/${restaurantId}/happy-menu-periods/${id}`);
  }

  public getWorkingHours(id: number): Observable<Period[]> {
    return this.http.get<Period[]>(`/restaurants/${id}/device-schedule-periods`);
  }

  public createWorkingHoursPeriod(id: number, period: NewPeriod): Observable<any> {
    return this.http.post(`/restaurants/${id}/device-schedule-periods`, {
      active: true,
      startDay: period.startDay,
      startTime: period.startTime,
      endDay: period.endDay,
      endTime: period.endTime
    });
  }

  public updateWorkingHoursPeriod(restaurantId: number, period: Period): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}/device-schedule-periods/${period.id}`, {
      active: true,
      startDay: period.startDay,
      startTime: period.startTime,
      endDay: period.endDay,
      endTime: period.endTime
    });
  }

  public deleteWorkingHours(restaurantId: number, id: number): Observable<any> {
    return this.http.delete(`/restaurants/${restaurantId}/device-schedule-periods/${id}`);
  }

  public uploadHappyHoursPdf(file: any, id: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('menu', file, file.name);
    return this.http.post(`/restaurants/happyMenu/upload/${id}`, data);
  }

  public updateClickableMediaConfig(id: number, items: any[]): Observable<any> {
    return this.http.patch(`/restaurants/${id}`, {
      clickableMediaConfig: JSON.stringify(items),
    });
  }

  public getClickableMediaConfig(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/clickableMediaConfig`);
  }

}
