import { environment } from '../../../environments/environment';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChainService } from '../../services/chain.service';
import { ActivatedRoute } from '@angular/router';
import { filter, pluck, startWith, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { WidgetUIConfiguration } from '../../interfaces/widget-ui-config';
import { Chain } from '../../interfaces/chain';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import { AuthService } from '../../services/auth/auth.service';

interface Palette {
  name: string;
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
}

@UntilDestroy()
@Component({
  selector: 'app-widget-page',
  templateUrl: './widget-page.component.html',
  styleUrls: ['./widget-page.component.scss'],
})
export class WidgetPageComponent {

  private chain: Chain | undefined;
  private currentConfig: WidgetUIConfiguration | undefined;

  public isEdited = false;
  public widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.widgetUrl);
  public idleDelaySubscription: any;
  public previewConfig = new BehaviorSubject<WidgetUIConfiguration | null>(null);
  public editable = false;
  public showSecondScreen = false;

  palettes: Palette[] = [
    {
      name: 'Oongalee',
      colors: {
        primary: '#8363EC',
        secondary: '#6C7487',
        tertiary: '#FCFCFC',
      }
    },
    {
      name: 'Sunset',
      colors: {
        primary: '#C0AB92',
        secondary: '#ECD2C5',
        tertiary: '#2F3834',
      }
    },
    {
      name: 'Sunrise',
      colors: {
        primary: '#A04D66',
        secondary: '#685844',
        tertiary: '#F3E8E5',
      }
    },
    {
      name: 'Night',
      colors: {
        primary: '#577BC1',
        secondary: '#738496',
        tertiary: '#161A1D',
      }
    }
  ];

  form: FormGroup = this.formBuilder.group({
    logo: new FormControl(null),
    idleDelay: new FormControl(null),
    tabBarAutoHide: new FormControl({value: false}),
    colors: this.formBuilder.group({
      primary: new FormControl(null, [Validators.required]),
      secondary: new FormControl(null, [Validators.required]),
      tertiary: new FormControl(null, [Validators.required])
    }),
    callWaiterButtons: this.formBuilder.group({
      callWaiter: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        lightsColor: new FormControl(null),
      }),
      anotherRound: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        lightsColor: new FormControl(null),
      }),
      requestBill: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        lightsColor: new FormControl(null),
      }),
    }),
    pages: this.formBuilder.group({
      menu: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        icon: new FormControl(null),
      }),
      serviceCenter: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        icon: new FormControl(null),
      }),
      invoice: this.formBuilder.group({
        enabled: new FormControl(true),
        name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        icon: new FormControl(null),
      }),
    }),
  });

  constructor(
    private readonly matDialog: MatDialog,
    private readonly sanitizer: DomSanitizer,
    private readonly formBuilder: FormBuilder,
    private readonly notifier: NotifierService,
    private readonly activateRoute: ActivatedRoute,
    private readonly chainService: ChainService,
    private readonly auth: AuthService
  ) {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.editable = !this.auth.isViewer(chain);
        this.chain = chain;
      }),
      untilDestroyed(this),
    ).subscribe();

    this.activateRoute.data.pipe(
      pluck('config'),
      untilDestroyed(this),
    ).subscribe((config: WidgetUIConfiguration) => {
      this.currentConfig = config;
      this.previewConfig.next(config);
      this.form.setValue(config);
    });

    this.form.valueChanges.pipe(
      filter(() => this.form.valid),
      untilDestroyed(this),
    ).subscribe(async (value) => {
      const prepareFiles = async (data: { [key: string]: any }): Promise<{ [key: string]: any }> => {
        const values = { ...data };

        for (const key of Object.keys(values)) {
          if (values[key] instanceof File) {
            values[key] = await this.blobToBase64(values[key]);
          } else if (typeof values[key] === 'object' && values[key] !== null) {
            values[key] = await prepareFiles(values[key]);
          }
        }

        return values;
      };

      const config = await prepareFiles(value);
      this.previewConfig.next(config as WidgetUIConfiguration);
    });

    this.idleDelaySubscription = this.form.get('idleDelay')?.valueChanges
      .pipe(
        startWith(this.form.get('idleDelay')?.value),
        untilDestroyed(this),
      )
      .subscribe((value) => {
      if (value === 0) {
        this.form.get('tabBarAutoHide')?.reset(false);
      }
    });
  }

  public applyPalette(palette: Palette): void {
    this.form.get('colors')?.setValue(palette.colors);
    this.form.markAsDirty();
  }

  public isCurrentPalette(palette: Palette): boolean {
    return (
      this.form.value.colors.primary === palette.colors.primary
      && this.form.value.colors.secondary === palette.colors.secondary
      && this.form.value.colors.tertiary === palette.colors.tertiary
    );
  }

  public onSubmit(): void {
    const modal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to update the widget UI configuration?',
        title: 'Update widget UI configuration',
      },
      panelClass: 'confirmation-modal'
    });

    modal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.isEdited = false;
      if (this.chain) {
        const values = this.preparationValues(this.form.value);

        this.chainService.updateWidgetUiConfig(
          this.chain.id,
          values.config,
          values.files,
        ).subscribe((config) => {
          this.currentConfig = config;
          this.form.reset(config);
          this.notifier.notify('success', 'Widget UI configuration updated');
        });
      }
    });
  }

  public onCancel(): void {
    if (this.currentConfig) {
      this.isEdited = false;
      this.form.reset(this.currentConfig);
    }
  }

  public onResetToFactory(): void {
    const modal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to reset the widget UI configuration to factory settings?',
        title: 'Reset to factory settings',
      },
      panelClass: 'confirmation-modal'
    });

    modal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      if (this.chain) {
        this.chainService.updateWidgetUiConfig(this.chain.id, null, null).subscribe((config) => {
          this.currentConfig = config;
          this.form.reset(config);
          this.notifier.notify('success', 'Widget UI configuration updated');
        });
      }
    });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private preparationValues(values: { [key: string]: any }): { config: WidgetUIConfiguration, files: {[key: string]: File} } {

    if (values.hasOwnProperty('idleDelay')) {
      values.idleDelay = parseInt(values.idleDelay, 10);
    }

    const config  = { ...values };

    const extractFilesFromValues = (data: { [key: string]: any }, prefix: string = ''): {[key: string]: File} => {
      const files: {[key: string]: File} = {};

      Object.keys(data).forEach((key) => {
        if (data[key] instanceof File) {
          files[prefix + key] = data[key];
          data[key] = null;
        } else if (typeof data[key] === 'object' && data[key] !== null) {
          const deepFiles = extractFilesFromValues(data[key], prefix + key + '.');
          Object.keys(deepFiles).forEach((dKey) => {
            files[dKey] = deepFiles[dKey];
          });
        }
      });

      return files;
    };
    return {
      config: config as WidgetUIConfiguration,
      files: extractFilesFromValues(config)
    };
  }

}
