import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { forkJoin, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IMenu } from '../../../interfaces/menu';
import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-add-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss'],
})
export class EditMenuComponent {
  public selected = 0;
  public languages = [
    { value: 'en-CA', viewValue: 'English' },
    { value: 'fr-CA', viewValue: 'French' },
    { value: null, viewValue: 'Not Set' },
  ];
  public menus = this.data.menusGroup;
  public isMainMenu = this.data.isMainMenu;
  public happyHours = this.data.menusGroup?.[0].happyHours;
  public menusFormArray!: FormArray;
  public isUpdating = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      restaurantId: number;
      isMainMenu: boolean;
      menusGroup: IMenu[];
    },
    private menuState: MenuStoreService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.menusFormArray = new FormArray(
      this.data.menusGroup.map(menu => this.createMenuFormGroup(menu))
    );
  }

  private createMenuFormGroup(menu: IMenu): FormGroup {
    return new FormGroup({
      id: new FormControl(menu.id),
      name: new FormControl(menu.name, [Validators.required, Validators.minLength(3)]),
      language: new FormControl(menu.language),
      hidden: new FormControl(menu.hidden),
      menuFile: new FormControl(menu.file || null, [Validators.required]),
      iconFile: new FormControl(menu.icon || null)
    });
  }

  get menusControls(): FormGroup[] {
    return this.menusFormArray.controls as FormGroup[];
  }

  public menuPreviewUrl(index: number): string {
      return this.menus[index]?.pages?.[0] ?? '';
  }

  public isLanguageAvailable(language: string | null, currentIndex: number): boolean {
    const selectedLanguages = this.menusControls
      .map((menu, index) => index !== currentIndex ? menu.controls.language.value : null)
      .filter(lang => lang !== null);

    return !selectedLanguages.includes(language) && !(selectedLanguages.length > 0 && language === null);
  }

  public addTranslate(): void {
    this.menusFormArray.push(new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      language: new FormControl(null, [Validators.required]),
      hidden: new FormControl(false),
      happyHours: new FormControl(this.happyHours),
      menuFile: new FormControl(null, [Validators.required]),
      iconFile: new FormControl(null)
    }));

    this.selected = this.menusFormArray.length - 1;
  }

  public removeTranslate(index: number, id: number): void {
    if (id) {
      const title = this.translate.instant('pages.menu.modal.delete');
      const message = this.translate.instant('pages.menu.messages.confirmDelete');
      const confirmationModal = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message,
          title
        },
        panelClass: 'confirmation-modal'
      });

      confirmationModal.afterClosed().pipe(
        filter(Boolean),
        untilDestroyed(this)
      ).subscribe((): void => {
        this.delete(this.data.restaurantId, id, index);
      });
    } else {
      this.menusFormArray.removeAt(index);
      this.selected = index;
    }
  }

  update(): void {
    this.isUpdating = true;

    const [menuOriginalData, ...menuTranslatedData] = this.menusControls;
    const originalMenuId = this.data.menusGroup[0].id;
    const menuType = this.isMainMenu ? 'mainMenu' : 'happyMenu';

    const updateMenu = (menuData: any) =>
      this.menuState.updateMenu(
        menuData.value.id,
        menuType,
        this.data.restaurantId,
        {
          name: menuData.value.name,
          language: menuData.value.language,
          happyHours: this.happyHours,
          hidden: menuData.value.hidden,
          menuFile: menuData.value.menuFile,
          iconFile: menuData.value.iconFile,
        }
      );

    const addMenu = (menuData: any, originalId: number | null) =>
      this.menuState.addMenu(
        this.data.restaurantId,
        menuData.value.name,
        menuData.value.language,
        this.happyHours,
        menuData.value.hidden,
        menuData.value.menuFile,
        originalId,
        menuData.value.iconFile
      );

    if (this.isChangedOriginal()) {
      updateMenu(menuOriginalData).pipe(
          switchMap((originalMenu) => {
            if (menuTranslatedData.length > 0) {
              return forkJoin(
                  menuTranslatedData.map((menuTranslated) => {
                        if (menuTranslated.value.id) {
                          return updateMenu(menuTranslated);
                        }

                        return addMenu(menuTranslated, originalMenu.id);
                      }
                  )
              );
            }
            return of(originalMenu);
          }),
          untilDestroyed(this)
      ).subscribe({
        next: () => {
          this.dialog.closeAll();
          this.isUpdating = false;
        },
        error: () => {
          this.isUpdating = false;
        }
      });
    } else {
      if (menuTranslatedData.length > 0 && this.isChangedTranslations()) {
        forkJoin(
            menuTranslatedData.map((menuTranslated) => {
                  if (menuTranslated.value.id) {
                    return updateMenu(menuTranslated);
                  }

                  return addMenu(menuTranslated, originalMenuId);
                }
            )
        ).subscribe({
          next: () => {
            this.dialog.closeAll();
            this.isUpdating = false;
          },
          error: () => {
            this.isUpdating = false;
          }
        });
      }
    }
  }

  delete(restaurantId: number, menuId: number, index: number): void {
    this.isUpdating = true;
    this.menuState
      .removeRestaurantsMenu(restaurantId, menuId)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
            this.menusFormArray.removeAt(index);

            if (this.menusFormArray.length === 0) {
              this.dialog.closeAll();
            } else {
              this.selected = index;
              this.isUpdating = false;
            }
          },
        () => (this.isUpdating = false)
      );
  }

  public isChangedOriginal(): boolean {
    const menuOriginalData = this.menusControls[0];
    const menu = this.data.menusGroup[0];

    return (
      menuOriginalData.value.name !== menu.name ||
      menuOriginalData.value.language !== menu.language ||
      menuOriginalData.value.hidden !== menu.hidden ||
      menuOriginalData.value.menuFile !== menu.file ||
      menuOriginalData.value.iconFile !== menu.icon
    );
  }

  public isChangedTranslations(): boolean {
    const menuTranslationData = this.menusControls.slice(1);
    const translation = this.data.menusGroup.slice(1);

    if (menuTranslationData.length !== translation.length) {
      return true;
    }

    return menuTranslationData.some((menuControl, index) => {
      const menu = translation[index];

      return (
        menuControl.value.name !== menu.name ||
        menuControl.value.language !== menu.language ||
        menuControl.value.hidden !== menu.hidden ||
        menuControl.value.menuFile !== menu.file ||
        menuControl.value.iconFile !== menu.icon
      );
    });
  }
}
