import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddMenuComponent } from '../../components/modals/add-menu/add-menu.component';
import { MenuStoreService } from '../../services/menu/menu-store.service';
import { IMenuGroup } from '../../interfaces/menu';
import { pluck, tap } from 'rxjs/operators';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { AuthService } from '../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss'],
})
export class MenuPageComponent implements OnInit {
  public isEdit = false;
  public restaurantId!: number;
  public restaurantInfoData!: IRestaurantInfo;
  public menus: IMenuGroup[] = [];
  public isLoading = false;
  public tabIndex = 0;
  public editable = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly menuStore: MenuStoreService,
    private readonly websocket: WebsocketService,
    private readonly auth: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.editable = !this.auth.isViewer(restaurant);
        this.restaurantInfoData = restaurant;
        this.restaurantId = restaurant.id;
        this.initData();
      }),
      untilDestroyed(this),
    ).subscribe();
    this.initWebsocket();
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        message.data.attachedRestaurantIds.map((id: number) => {
          if (id === this.restaurantId) {
            this.menuStore.fetchMenu(this.restaurantId, message.data.id);
          }
        });
      }
    });
  }

  createMenu(): void {
    this.dialog.open(AddMenuComponent, {
      data: { restaurantId: this.restaurantInfoData.id, happyHours: false },
    });
  }


  initData(): void {
    this.menuStore.fetchMenus({
      restaurantId: this.restaurantInfoData.id,
      menuType: 'mainMenu',
    });

    this.menuStore.getMenus$.pipe(
      untilDestroyed(this)
    ).subscribe((menus) => {
      this.menus = menus;
    });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => {
      this.isLoading = load;
    });
  }

}
