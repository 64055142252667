import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { Period } from '../interfaces/period';
import * as moment from 'moment/moment';

export const nullIfEmptyValidator = (control: AbstractControl) => {
  if (control.value === '') {
    control.setValue(null);
  }
  return null;
};

export const wifiNameAndPasswordValidator = (control: AbstractControl): ValidationErrors | null => {
  const wifiNameControl = control.get('wifiName');
  const wifiPasswordControl = control.get('wifiPassword');

  if (wifiPasswordControl?.value && !wifiNameControl?.value) {
    wifiNameControl?.setErrors({ required: true });
  } else {
    wifiNameControl?.setErrors(null);
  }
  return null;
};

export function passwordFormValidator(newPassControlName: string, oldPassControlName: string, enable: boolean): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!enable) {
      return null;
    }

    const newPassCtrl = group.get(newPassControlName);
    const oldPassCtrl = group.get(oldPassControlName);

    if (newPassCtrl?.value && !oldPassCtrl?.value) {
      oldPassCtrl?.setErrors({ required: true });
      oldPassCtrl?.markAsDirty();
      oldPassCtrl?.markAsTouched();
    } else if (oldPassCtrl?.value && !newPassCtrl?.value) {
      newPassCtrl?.setErrors({ required: true });
      newPassCtrl?.markAsDirty();
      newPassCtrl?.markAsTouched();
    } else {
      removeError(oldPassCtrl, 'required');
      removeError(newPassCtrl, 'required');
    }

    return null;
  };
}

export function dayStartValidator(periods: Period[], id: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root as AbstractControl;
    const dayStart = root.get('startDay')?.value;

    const hasConflict = periods.some(period => {
      if (id && period.id === id) {
        return false;
      }

      if (period.endDay < period.startDay) {
        return dayStart < period.endDay || dayStart > period.startDay;
      }

      return dayStart > period.startDay && dayStart < period.endDay;
    });

    if (hasConflict) {
      return { dayStartConflict: true };
    }

    return null;
  };
}

export function timeStartValidator(periods: Period[], id: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root as AbstractControl;
    const dayStart = root.get('startDay')?.value;
    const timeStart = control.value;

    if (timeStart && dayStart && periods) {
      const hasConflict = periods.some((period) => {

        if (id && period.id === id) {
          return false;
        }

        if (period.endDay < period.startDay) {
          const crossesStartDay =
            dayStart === period.startDay && timeStart >= period.startTime;
          const crossesEndDay =
            dayStart === period.endDay && timeStart <= period.endTime;
          return crossesStartDay || crossesEndDay;
        }

        const withinSameDay =
          dayStart === period.startDay && dayStart === period.endDay &&
          timeStart >= period.startTime && timeStart <= period.endTime;

        const withinRange =
          dayStart > period.startDay && dayStart < period.endDay;

        const atStartBoundary =
          dayStart === period.startDay &&
          timeStart >= period.startTime &&
          !(dayStart === period.endDay && timeStart > period.endTime);

        const atEndBoundary =
          dayStart === period.endDay &&
          timeStart <= period.endTime &&
          !(dayStart === period.startDay && timeStart < period.startTime);

        return withinSameDay || withinRange || atStartBoundary || atEndBoundary;
      });

      if (hasConflict) {
        return { timeStartConflict: true };
      }
    }

    return null;
  };
}

export function dayEndValidator(periods: Period[], id: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root as AbstractControl;
    const dayStart = root.get('startDay')?.value;
    const dayEnd = root.get('endDay')?.value;

    const hasConflict = periods.some(period => {
      if (id && period.id === id) {
        return false;
      }

      if (period.endDay < period.startDay) {
        return dayEnd > period.startDay || dayEnd < period.endDay;
      }

      if (dayEnd < dayStart) {
        return dayEnd > period.startDay || dayEnd > period.endDay;
      }

      const isInside =
        ((dayStart > period.startDay && dayEnd < period.endDay) && dayEnd > dayStart) ||
        (dayStart < period.startDay && dayEnd > period.endDay);

      const overlaps = dayStart < period.endDay && dayEnd > period.startDay;

      return isInside || overlaps;
    });

    if (hasConflict) {
      return { dayEndConflict: true };
    }

    return null;
  };
}


export function timeEndValidator(periods: Period[], id: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const root = control.root as AbstractControl;
    const dayStart = root.get('startDay')?.value;
    const dayEnd = root.get('endDay')?.value;
    const timeStart = root.get('startTime')?.value;
    const timeEnd = control.value;

    if (dayStart !== null && dayEnd !== null && dayStart === dayEnd) {
      if (timeEnd !== null && timeStart !== null) {
        if (dayStart === dayEnd && timeStart === timeEnd) {
          return { timeEndEqualToStart: true };
        }
      }
    }

    if (dayStart !== null && timeStart !== null && dayEnd !== null && timeEnd !== null && periods) {
      const hasConflict = periods.some(period => {
        if (id && period.id === id) {
          return false;
        }

        if (period.endDay < period.startDay) {
          if (dayStart < period.endDay || dayStart > period.startDay) {
            return true;
          }

          if (dayEnd >= period.startDay || dayEnd <= period.endDay) {
            return timeEnd >= period.startTime || timeEnd <= period.endTime;
          }

          return false;
        }

        if (dayEnd < dayStart || (dayEnd === dayStart && timeStart > timeEnd)) {
          if (dayEnd > period.startDay || dayStart < period.endDay) {
            return true;
          } else if (dayEnd === period.startDay && timeEnd >= period.startTime) {
            return true;
          } else if (dayStart === period.endDay && timeStart <= period.endTime) {
            return true;
          }
        }

        const overlaps =
          (period.startDay <= dayEnd && period.endDay >= dayStart) &&
          (period.startTime <= timeEnd && period.endTime >= timeStart);

        const isContained =
          dayStart <= period.startDay && dayEnd >= period.endDay &&
          timeStart <= period.startTime && timeEnd >= period.endTime;

        return overlaps || isContained;
      });

      if (hasConflict) {
        return { timeEndConflict: true };
      }
    }

    return null;
  };
}



function removeError(control: AbstractControl | null, errorKey: any): void {
  const errors: any = control?.errors;
  let processedErrors: ValidationErrors | null = {};

  if (errors && errors instanceof Object) {
    Object.keys(errors).forEach((key: string) => {
      if (key !== errorKey && processedErrors && errors) {
        processedErrors[key] = errors[key];
      }
    });
  }

  if (Object.keys(processedErrors).length < 1) {
    processedErrors = null;
  }

  control?.setErrors(processedErrors);
}
