import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
})
export class AddMenuComponent {
  public isLoading = false;
  public selected = 0;
  public languages = [
    { value: 'en-CA', viewValue: 'English' },
    { value: 'fr-CA', viewValue: 'French' },
    { value: null, viewValue: 'Not Set' },
  ];
  public happyHours = this.data.happyHours;

  public menusFormArray = new FormArray([
    new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      language: new FormControl(null),
      hidden: new FormControl(false),
      menuFile: new FormControl(null, [Validators.required]),
      iconFile: new FormControl(null)
    })
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { restaurantId: number; happyHours: boolean; hidden: boolean },
    private menuState: MenuStoreService,
    private dialog: MatDialog,
  ) {}

  get menusControls(): FormGroup[] {
    return this.menusFormArray.controls as FormGroup[];
  }

  public isLanguageAvailable(language: string | null, currentIndex: number): boolean {
    const selectedLanguages = this.menusControls
      .map((menu, index) => index !== currentIndex ? menu.controls.language.value : null)
      .filter(lang => lang !== null);

    return !selectedLanguages.includes(language) && !(this.menusControls.length > 1 && language === null);
  }

  public addTranslate(): void {
    this.menusFormArray.push(new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      language: new FormControl(null, [Validators.required]),
      hidden: new FormControl(false),
      happyHours: new FormControl(this.data.happyHours),
      menuFile: new FormControl(null, [Validators.required]),
      iconFile: new FormControl(null)
    }));

    this.selected = this.menusFormArray.length - 1;
  }

  public removeTranslate(index: number): void {
    this.menusFormArray.removeAt(index);
    this.selected = index;
  }

  onSubmit(): void {
    this.isLoading = true;

    const [menuOriginalData, ...menuTranslatedData] = this.menusControls;

    const addMenu = (menuData: any, originalId: number | null) =>
      this.menuState.addMenu(
        this.data.restaurantId,
        menuData.value.name,
        menuData.value.language,
        this.happyHours,
        menuData.value.hidden,
        menuData.value.menuFile,
        originalId,
        menuData.value.iconFile
      );

    addMenu(menuOriginalData, null).pipe(
      switchMap((menu) => {
        if (menuTranslatedData.length > 0) {
          return forkJoin(menuTranslatedData.map((translate) => {
            addMenu(translate, menu.id);
          }));
        }
        return of(menu);
      }),
      untilDestroyed(this)
    ).subscribe({
      next: () => (
        this.isLoading = false,
        this.dialog.closeAll()
      ),
      error: () => (
        this.isLoading = false,
        this.dialog.closeAll()
      )
    });
  }
}
