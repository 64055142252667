import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { IMenu, IMenuGroup } from '../../../interfaces/menu';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { isFormChanged } from '../../../shared/function';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { EditMenuComponent } from '../../modals/edit-menu/edit-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  private edit = false;
  public menuData!: IMenuGroup;

  @Input() set menu(menu: IMenuGroup) {
    this.menuData = menu;
  }

  @Input() set isEdit(value: boolean) {
    this.edit = value && this.menuData.ownerType === 'RESTAURANT';
  }
  @Input() restaurantId!: number;
  @Input() isMainMenu = true;
  isUpdating = false;

  // tslint:disable-next-line:adjacent-overload-signatures
  get menu(): IMenuGroup {
    return this.menuData;
  }

  get menuImage(): string {
    if (this.menu.pages && this.menu.pages?.length > 0) {
      return this.menu.pages[0];
    }
    else {
      return '';
    }
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get isEdit(): boolean {
    return this.edit;
  }

  constructor(
    private dialog: MatDialog,
  ) {}

  openEdit(): void {
    this.dialog.open(EditMenuComponent, {
      data: {
        restaurantId: this.restaurantId,
        isMainMenu: this.isMainMenu,
        menusGroup: [this.menuData],
      }
    });
  }
}
