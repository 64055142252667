<div class="app-header">
  <div class="app-header__logo">
    <img src="/assets/icons/oongalee-logo.png">

    <button
      class="back"
      *ngIf="backLink"
      (click)="back()"
    >
      <fa-icon [icon]="icons.back"></fa-icon>
      <span>{{ 'navigation.back' | translate }}</span>
    </button>
  </div>

  <div class="app-header__menu" *ngIf="links">
    <ul>
      <li
        *ngFor="let link of links"
        class="app-header__menu-item"
        [routerLink]="[link.route]"
        routerLinkActive="active"
      >
        <a>{{ link.title | translate }}</a>
      </li>
    </ul>
  </div>
  <div class="app-header__logout">
    <button class="btn btn__link" (click)="confirmToLogout()">{{ 'navigation.logOut' | translate }}</button>
  </div>
</div>
