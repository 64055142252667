import { HttpParams } from '@angular/common/http';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const createHttpParams = (params: {
  [key: string]: string | number;
}): HttpParams => {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach((param) => {
    if (params[param] || params[param] === 0) {
      httpParams = httpParams.set(param, params[param].toString());
    }
  });

  return httpParams;
};

const isFormChanged = (oldValues: {
  name: string;
  happyHours: boolean;
  hidden: boolean;
}): ValidatorFn => {
  return (newValues: AbstractControl): ValidationErrors | null => {
    const isFormHasBeenChanged = Boolean(
      oldValues.name !== newValues.value.name ||
      oldValues.happyHours !== newValues.value.happyHours ||
      newValues.value.menuFile ||
      newValues.value.iconFile ||
      oldValues.hidden !== newValues.value.hidden
    );
    return isFormHasBeenChanged ? null : { formChanged: false };
  };
};

const arraysEqual = (a: number[] | null, b: number[] | null) => {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export { createHttpParams, isFormChanged, arraysEqual };
