<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.menu.modal.title' | translate }}</h1>
    <mat-tab-group
      [selectedIndex]="selected"
      (selectedIndexChange)="selected=($event)"
    >
      <mat-tab
        *ngFor="let menu of menusControls; index as i"
        [formGroup]="menu"
      >
        <ng-template matTabLabel>
          {{ 'pages.menu.modal.language.'+menu.controls.language.value | translate }}
          <button
            class="btn btn-delete__small"
            *ngIf="this.menusFormArray.length !== 1"
            (click)="removeTranslate(i)"
          >
            <mat-icon color="warn">delete</mat-icon>
          </button>

        </ng-template>
        <div class="menu_params">
          <mat-form-field class="login-wrap__text">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language" [value]="null">
              <mat-option
                *ngFor="let lang of languages"
                [value]=lang.value
                [disabled]="!isLanguageAvailable(lang.value, i)"
              >
                {{ 'pages.menu.modal.language.'+lang.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="login-wrap__text">
            <mat-label>{{ 'pages.menu.modal.name' | translate }}</mat-label>
            <input matInput appFormInputTrim type="text" formControlName="name" />
            <mat-error *ngIf="menu.controls.name.touched">
              <span *ngIf="menu.controls.name.errors?.required">{{ 'pages.menu.modal.required' | translate }}</span>
              <span *ngIf="menu.controls.name.errors?.minlength">{{ 'pages.menu.modal.min' | translate }}</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="files">
          <div class="files__item">
            <mat-label class="label">{{ 'pages.menu.modal.upload' | translate }}</mat-label>
            <app-form-file-upload
              appDragAndDropFiles
              formControlName="menuFile"
              accept="application/pdf"
            >
              {{ 'formFilUpload.dragAndDrop' | translate }}
            </app-form-file-upload>
          </div>
          <div class="files__item">
            <mat-label class="label">{{ 'pages.menu.modal.icon' | translate }}</mat-label>
            <app-form-file-upload
              appDragAndDropFiles
              formControlName="iconFile"
              accept="image/svg+xml"
            >
              {{ 'formFilUpload.dragAndDrop' | translate }}
            </app-form-file-upload>
          </div>
        </div>
        <mat-slide-toggle
          formControlName="hidden"
          color="primary"
        >
          {{ 'pages.menu.menuContent.hide' | translate }}
        </mat-slide-toggle>
      </mat-tab>
    </mat-tab-group>
  <div class="mat-dialog-controls">
    <mat-slide-toggle
      [(ngModel)]="happyHours"
      color="primary"
    >
      {{ 'pages.menu.menuContent.happyHours' | translate }}
    </mat-slide-toggle>
    <button
      class="btn add-language-btn"
      [disabled]="isLoading || menusControls[selected]?.controls?.language?.value === null || menusFormArray.invalid"
      (click)="addTranslate()"
    >
      <mat-icon>add</mat-icon>
      {{ 'pages.menu.modal.addLanguage' | translate }}
    </button>
  </div>
  <mat-dialog-actions class="mat-dialog-actions menu">
    <div class="btn__skeleton-loader" *ngIf="isLoading; else submitBtn">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <ng-template #submitBtn>
      <button
        class="btn btn__primary"
        type="submit"
        [disabled]="menusFormArray.invalid || isLoading"
        (click)="onSubmit()"
      >{{ 'pages.menu.modal.createMenu' | translate }}
      </button>
    </ng-template>
  </mat-dialog-actions>
</div>
