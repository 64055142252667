import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotifierModule } from 'angular-notifier';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RestaurantsPageComponent } from './pages/restaurants-page/restaurants-page.component';
import { AccountModule } from './modules/account/account.module';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AddRestaurantComponent } from './components/modals/add-restaurant/add-restaurant.component';
import { AddTableComponent } from './components/modals/add-table/add-table.component';
import { AddWaiterComponent } from './components/modals/add-waiter/add-waiter.component';
import { AuthService } from './services/auth/auth.service';
import { AdminService } from './services/admin/admin.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorCatchingInterceptor } from './interceptors/error-catching.interceptor';
import { RestaurantService } from './services/restaurant/restaurant.service';
import { TablesComponent } from './components/tables/tables.component';
import { TablesPageComponent } from './pages/tables-page/tables-page.component';
import { MenuComponent } from './components/common/menu/menu.component';
import { MenuPageComponent } from './pages/menu-page/menu-page.component';
import { SpecialOfferPageComponent } from './pages/special-offer-page/special-offer-page.component';
import { RestaurantAccountPageComponent } from './pages/restaurant-account-page/restaurant-account-page.component';
import { WaitersPageComponent } from './pages/waiters-page/waiters-page.component';
import { EditWaiterComponent } from './components/modals/edit-waiter/edit-waiter.component';
import { HappyHoursPageComponent } from './pages/happy-hours-page/happy-hours-page.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AddMenuComponent } from './components/modals/add-menu/add-menu.component';
import * as Sentry from '@sentry/angular';
import { EditTableComponent } from './components/modals/edit-table/edit-table.component';
import { ConfirmationDialogComponent } from './components/modals/confirm-dialog/confirmation-dialog.component';
import { AddNewWidgetCodeComponent } from './components/modals/add-new-widget-code/add-new-widget-code.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ChainsPageComponent } from './pages/chains-page/chains-page.component';
import { ChainMenuPageComponent } from './pages/chains-page/chain-menu-page/chain-menu-page.component';
import { ChainHappyHoursPageComponent } from './pages/chains-page/chain-happy-hours-page/chain-happy-hours-page.component';
import { ChainSettingsPageComponent } from './pages/chains-page/chain-settings-page/chain-settings-page.component';
import { AddChainComponent } from './components/modals/add-chain/add-chain.component';
import { AddChainMenuComponent } from './components/modals/add-chain-menu/add-chain-menu.component';
import { ChainMenuComponent } from './components/common/chain-menu/chain-menu.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WidgetPageComponent } from './pages/widget-page/widget-page.component';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { FormFilUploadComponent } from './components/common/form-file-upload/form-fil-upload.component';
import { DragAndDropFilesDirective } from './direcitves/drag-and-drop-files.directive';
import { MatRadioModule } from '@angular/material/radio';
import { DeviceComponent } from './components/common/device/device.component';
import { WaiterTablesComponent } from './pages/waiters-page/waiter-tables/waiter-tables.component';
import { WaiterTablesModalComponent } from './components/modals/waiter-tables-list/waiter-tables-modal.component';
import { AddIntegrationComponent } from './components/modals/add-integration/add-integration.component';
import { EditIntegrationComponent } from './components/modals/edit-integration/edit-integration.component';
import { SpecialsPageComponent } from './pages/specials-page/specials-page.component';
import { AddSpecialComponent } from './components/modals/add-special/add-special.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SpecialComponent } from './components/common/special/special.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditSpecialComponent } from './components/modals/edit-special/edit-special.component';
import { CallWaiteConfigComponent } from './components/modals/call-waite-config/call-waite-config.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormInputTrimDirective } from './direcitves/form-input-trim.directive';
import { SearchComponent } from './components/common/search/search.component';
import { MatIconModule } from '@angular/material/icon';
import { HappyHoursPeriodComponent } from './components/happy-hours-period/happy-hours-period.component';


@NgModule({
  declarations: [
    DragAndDropFilesDirective,
    FormInputTrimDirective,
    FormFilUploadComponent,
    DeviceComponent,
    AppComponent,
    RestaurantsPageComponent,
    LoginPageComponent,
    AddRestaurantComponent,
    AddTableComponent,
    AddWaiterComponent,
    EditWaiterComponent,
    TablesComponent,
    TablesPageComponent,
    MenuComponent,
    MenuPageComponent,
    SpecialOfferPageComponent,
    RestaurantAccountPageComponent,
    WaitersPageComponent,
    HappyHoursPageComponent,
    AddMenuComponent,
    SearchFilterPipe,
    EditTableComponent,
    ConfirmationDialogComponent,
    AddNewWidgetCodeComponent,
    ChainsPageComponent,
    ChainMenuPageComponent,
    ChainHappyHoursPageComponent,
    ChainSettingsPageComponent,
    AddChainComponent,
    AddChainMenuComponent,
    ChainMenuComponent,
    WidgetPageComponent,
    WaiterTablesComponent,
    WaiterTablesModalComponent,
    AddIntegrationComponent,
    EditIntegrationComponent,
    SpecialsPageComponent,
    AddSpecialComponent,
    SpecialComponent,
    EditSpecialComponent,
    CallWaiteConfigComponent,
    SearchComponent,
    HappyHoursPeriodComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AccountModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxSkeletonLoaderModule,
    NgxMatSelectSearchModule,
    FontAwesomeModule,
    QRCodeModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en-CA',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/locale/', '.json'),
        deps: [HttpClient]
      }
    }),
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatIconModule,
  ],
  providers: [
    AuthService,
    AdminService,
    RestaurantService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
