import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { IChainMenu, IChainMenuGroup, IMenu, IMenuGroup } from '../../interfaces/menu';
import { MenuService } from './menu.service';
import { map, share, shareReplay, switchMap } from 'rxjs/operators';
import { FileUploadService } from '../file-upload.service';

interface IMenuState {
  menus: IMenu[];
  chainMenus: IChainMenu[];
  loader: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MenuStoreService {
  constructor(
    private menuService: MenuService,
    private fileUpload: FileUploadService,
  ) {}

  private state$ = new BehaviorSubject<IMenuState>({
    menus: [],
    chainMenus: [],
    loader: false,
  });

  public fetchMenus({
    restaurantId,
    sort,
    order,
    menuType,
  }: {
    restaurantId: number;
    sort?: string;
    order?: string;
    menuType?: string;
  }): void {
    this.toggleLoader(true);

    this.menuService.getRestaurantMenus({ restaurantId, sort, order, menuType }).subscribe((result) => {
      const state = this.state$.getValue();
      this.state$.next({
        ...state,
        menus: result,
        loader: false,
      });
    }, () => {
      this.toggleLoader(false);
    });
  }

  public fetchMenu(restaurantId: number, menuId: number): void {
    this.toggleLoader(true);
    this.menuService.getRestaurantMenuById(restaurantId, menuId).subscribe((result) => {
      const state = this.state$.getValue();

      const menuExist = state.menus.some(menu => menu.id === result.id);

      this.state$.next({
       ...state,
        menus: menuExist
          ? state.menus.map((menu) =>
            menu.id === result.id ? result : menu
            )
          : [result, ...state.menus],
        loader: false,
      });
    }, () => {
      this.toggleLoader(false);
    });
  }

  public addMenu(
    restaurantId: number,
    name: string,
    language: string,
    happyHours: boolean,
    hidden: boolean,
    file: File,
    originalId: number | null,
    iconFile?: File,
  ): Observable<IMenu> {
    const menuFileUpload$: Observable<string> = this.fileUpload.upload(file).pipe(
      map(event => event.intent.fileId.toString())
    );

    let iconFileUpload$: Observable<string | null> = of(null);

    if (iconFile) {
      iconFileUpload$ = this.fileUpload.upload(iconFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    const sub$ = forkJoin([menuFileUpload$, iconFileUpload$]).pipe(
      switchMap(([menuFileId, iconFileId]) => {
        return this.menuService.addMenu(
          restaurantId,
          menuFileId,
          name,
          language,
          happyHours,
          hidden,
          originalId,
          iconFileId
        );
      }),
      share()
    );

    sub$.subscribe((newItem) => {
      const state = this.state$.getValue();
      const menus = [newItem, ...state.menus];

      this.state$.next({
        ...state,
        menus,
      });
    });

    return sub$;
  }

  public updateMenu(
    menuId: number,
    menuType: string,
    restaurantId: number,
    data: {
      name: string;
      language: string | null;
      hidden: boolean;
      menuFile: File | null;
      happyHours: boolean;
      iconFile: File | null;
    }
  ): Observable<IMenu> {
    let menuFileUpload$: Observable<string | null> = of(null);
    let iconFileUpload$: Observable<string | null> = of(null);

    if (data.menuFile && data.menuFile.size) {
      menuFileUpload$ = this.fileUpload.upload(data.menuFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    if (data.iconFile && data.iconFile.size) {
      iconFileUpload$ = this.fileUpload.upload(data.iconFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    const updateObservable$ = forkJoin([menuFileUpload$, iconFileUpload$]).pipe(
      switchMap(([menuFileId, iconFileId]) => {
        return this.menuService.updateMenu(
          restaurantId,
          menuId,
          data.name,
          data.language,
          data.happyHours,
          data.hidden,
          menuFileId,
          iconFileId
        );
      }),
      share()
    );

    updateObservable$.subscribe(() => {
      this.fetchMenus({ restaurantId, menuType });
    });

    return updateObservable$;
  }

  public toggleLoader(value: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({
      ...state,
      loader: value,
    });
  }

  get getMenus$(): Observable<IMenuGroup[]> {
    return this.state$.asObservable().pipe(
      map((state) => state.menus),
      map(menus => {
        return menus
          .sort((a, b) => (a.ownerType === 'CHAIN' ? -1 : 1))
          .filter((menu) => !menu.originalId)
          .map((item) => ({
            ...item,
            translation: menus.filter((menu) => menu.originalId === item.id)
          })) as IMenuGroup[];
      }),
      shareReplay(1)
    );
  }

  get getChainMenus$(): Observable<IChainMenuGroup[]> {
    return this.state$.pipe(
      map((state) => state.chainMenus),
      map(menus => {
        return menus.filter((menu) => !menu.originalId).map((item) => {
          return {
            ...item,
            translation: menus.filter((menu) => menu.originalId === item.id)
          };
        }) as IChainMenuGroup[];
      }),
      shareReplay(1)
    );
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.loader)
    );
  }

  public fetchChainMenus({
    chainId,
    sort,
    order,
    menuType,
  }: {
    chainId: number;
    sort?: string;
    order?: string;
    menuType?: string;
  }): void {
    this.toggleLoader(true);

    this.menuService.getChainMenus({ chainId, sort, order, menuType }).subscribe((result) => {
      const state = this.state$.getValue();
      this.state$.next({
        ...state,
        chainMenus: result,
        loader: false,
      });
    }, () => {
      this.toggleLoader(false);
    });
  }

  public fetchChainMenu(chainId: number, menuId: number): void {
    this.toggleLoader(true);
    this.menuService.getChainMenuById(chainId, menuId).subscribe(
      (result) => {
        const state = this.state$.getValue();
        const menuExists = state.chainMenus.some(menu => menu.id === result.id);

        this.state$.next({
          ...state,
          chainMenus: menuExists
            ? state.chainMenus.map((menu) =>
              menu.id === result.id ? result : menu
            )
            : [result, ...state.chainMenus],
          loader: false,
        });
      },
      () => {
        this.toggleLoader(false);
      }
    );
  }

  public addChainMenu(
    chainId: number,
    name: string,
    language: string,
    happyHours: boolean,
    hidden: boolean,
    file: File,
    selectedRestaurants: number[] | null,
    originalId: number | null,
    iconFile?: File
  ): Observable<IChainMenu> {
    const menuChainFileUpload$: Observable<string> = this.fileUpload.upload(file).pipe(
      map(event => event.intent.fileId.toString())
    );

    let iconFileUpload$: Observable<string | null> = of(null);

    if (iconFile) {
      iconFileUpload$ = this.fileUpload.upload(iconFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    const sub$ = forkJoin([menuChainFileUpload$, iconFileUpload$]).pipe(
      switchMap(([menuFileId, iconFileId]) => {
        return this.menuService.addChainMenu(
          chainId,
          menuFileId,
          name,
          language,
          happyHours,
          hidden,
          originalId,
          selectedRestaurants,
          iconFileId
        );
      }),
      share()
    );

    sub$.subscribe((newItem) => {
      const state = this.state$.getValue();
      const chainMenus = [newItem, ...state.chainMenus];

      this.state$.next({
        ...state,
        chainMenus,
      });
    });

    return sub$;
  }

  public updateChainMenu(
    menuId: number,
    chainId: number,
    menuType: string,
    selectedRestaurants: number[] | null,
    data: {
      name: string;
      language: string | null;
      happyHours: boolean,
      hidden: boolean;
      menuFile: File | null;
      iconFile?: File | null;
    }
  ): Observable<IChainMenu> {
    let menuChainFileUpload$: Observable<string | null> = of(null);
    let iconFileUpload$: Observable<string | null> = of(null);

    if (data.menuFile && data.menuFile.size) {
      menuChainFileUpload$ = this.fileUpload.upload(data.menuFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    if (data.iconFile && data.iconFile.size) {
      iconFileUpload$ = this.fileUpload.upload(data.iconFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    const sub$ = forkJoin([menuChainFileUpload$, iconFileUpload$]).pipe(
      switchMap(([menuFileId, iconFileId]) => {
        return this.menuService.updateChainMenu(
          menuId,
          chainId,
          data.name,
          data.language,
          data.happyHours,
          data.hidden,
          selectedRestaurants,
          menuFileId,
          iconFileId
        );
      }),
      share()
    );

    sub$.subscribe(() => {
      this.fetchChainMenus({ chainId, menuType });
    });

    return sub$;
  }

  public removeRestaurantsMenu(restaurantsId: number, menuId: number): Observable<any> {
    const sub$ = this.menuService.deleteRestaurantMenu(restaurantsId, menuId);

    sub$.subscribe(() => {
      const state = this.state$.getValue();
      const newArr = state.menus.filter((menu) => menu.id !== menuId);
      this.state$.next({
        ...state,
        menus: newArr,
      });
    });

    return sub$;
  }

  public removeChainMenu(chainId: number, menuId: number): Observable<any> {
    const sub$ = this.menuService.deleteChainMenu(chainId, menuId);

    sub$.subscribe(() => {
      const state = this.state$.getValue();
      const newArr = state.chainMenus.filter((chainMenus) => chainMenus.id !== menuId);
      this.state$.next({
        ...state,
        chainMenus: newArr,
      });
    });

    return sub$;
  }
}
