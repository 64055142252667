<script src="happy-hours-page.component.spec.ts"></script>
<div class="hours__wrap">
  <div [ngClass]="{'lds-ring': isLoading}">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <h2 class="hours__title">{{ 'pages.happyHours.title' | translate }}</h2>
  <div class="title" *ngIf="happyHourPeriods.length || isEditPeriods">
    <h3 class="hours__subtitle">{{ 'pages.happyHours.periodStart' | translate }}</h3>
    <h3 class="hours__subtitle">{{ 'pages.happyHours.periodEnd' | translate }}</h3>
  </div>

  <div class="periods">
    <app-period
      *ngFor="let period of happyHourPeriods"
      [happyHourPeriod]="period"
      [periods]="happyHourPeriods"
      periodType="happy"
      [editable]="editable"
      (update)="updatePeriod($event)"
      (delete)="deletePeriod($event)"
    >
    </app-period>

    <div class="period__empty" *ngIf="!isEditPeriods">
      <span class="noPeriods" *ngIf="!isEditPeriods && !happyHourPeriods.length">{{ 'period.happy.noPeriods' | translate }}</span>
      <button class="btn btn__primary btn__icon" *ngIf="!isEditPeriods && editable" (click)="isEditPeriods = true">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'period.happy.add' | translate }}</span>
      </button>
    </div>

    <app-period
      *ngIf="isEditPeriods"
      [periods]="happyHourPeriods"
      periodType="happy"
      (newPeriod)="createPeriod($event)"
      (cancel)="isEditPeriods = $event"
    >
    </app-period>
  </div>

  <!-- Happy hours menu -->
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.happyHours.menus' | translate }}</h1>
    <div class="actions" *ngIf="editable">
      <button type="button" (click)="createMenu()" *ngIf="isEdit" class="btn btn__primary btn__icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'pages.happyHours.addMenu' | translate }}</span>
      </button>
      <button type="button" *ngIf="!isEdit; else toggleEdit" class="btn btn__icon btn__secondary" (click)="isEdit = true">
        <span>{{ 'pages.happyHours.manage' | translate }}</span>
      </button>
      <ng-template #toggleEdit>
        <button type="button" class="btn btn__icon btn__active" (click)="isEdit = false">
          <span>{{ 'pages.happyHours.done' | translate }}</span>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="menu__skeleton" *ngIf="isMenuLoading; else content">
    <div class="menu__skeleton-loader" *ngFor="let i of [].constructor(2)">
      <div class="menu__skeleton-row img">
        <div class="menu__skeleton-field img">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="menu__skeleton-row info">
        <div class="menu__skeleton-field info">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="menu__skeleton-row info">
        <div class="menu__skeleton-field info">
          <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <div class="menu__not-found" *ngIf="!isMenuLoading && !menus.length">
    <span>{{ 'pages.happyHours.noMenus' | translate }}</span>
  </div>
  <ng-template #content>
    <div class="menu__wrapper">
      <app-menu-table
        [menus]="menus"
        [isEdit]="isEdit"
        [isMainMenu]="false"
        [restaurantId]="restaurantInfo.id"
      ></app-menu-table>
    </div>
  </ng-template>
  <!-- End happy hours menu -->
</div>
