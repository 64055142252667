<form class="search"
      [formGroup]="search" [ngClass]="{'search_disabled': isDisabled}">
  <input class="form-control"
         type="text"
         name="search"
         autocomplete="off"
         formControlName="searchInput"
         [placeholder]=placeholder
  >
  <div class="search-btn">
    <button>
      <fa-icon [icon]="icons.search"></fa-icon>
    </button>
  </div>
    <button
      class="btn clear-btn"
      [ngClass]="{'hidden': searchInput.length <= 0}"
      (click)="clearSearch()"
    >
      <fa-icon [icon]="icons.clear"></fa-icon>
    </button>
</form>






