import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { HeaderMenuItem } from '../../../interfaces/header-links';
import { filter } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../modals/confirm-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() links: HeaderMenuItem[] = [];
  @Input() backLink: UrlTree | string | any[] | null = null;
  @Input() restaurantName: string | null = null;
  @Input() chainName: string | null = null;

  public icons = {
    back: faArrowLeft
  };

  constructor(
    private translate: TranslateService,
    private notifier: NotifierService,
    private matDialog: MatDialog,
    public auth: AuthService,
    private router: Router,
  ) {}

  public confirmToLogout(): void {
    const title = this.translate.instant('login.logoutConfirm.title');
    const message = this.translate.instant('login.logoutConfirm.message');

    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.logout();
    });
  }

  private logout(): void{
    this.auth.logout().subscribe(() => {
      this.notifier.notify('success', this.translate.instant('login.messages.logout'));
    });
  }

 public back(): void {
    if (Array.isArray(this.backLink)) {
      this.router.navigate(this.backLink);
    } else if (this.backLink) {
      this.router.navigateByUrl(this.backLink);
    }
  }
}
