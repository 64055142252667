<div class="menu__skeleton-loader" *ngIf="isUpdating; else content">
  <div class="menu__skeleton-row img">
    <div class="menu__skeleton-field img">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="menu__skeleton-row info">
    <div class="menu__skeleton-field info">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="menu__skeleton-row info">
    <div class="menu__skeleton-field info">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
</div>

<ng-template #content>
  <div
    class="menu__item"
    *ngIf="menu && !menu.originalId"
  >
    <button class="btn edit" *ngIf="isEdit" (click)="openEdit()">
      <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#7973CA">
        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0l-30.1 30 97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2l-18.7-18.6zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5l167.3-167.4-98-98-167.3 167.4zM96 64c-53 0-96 43-96 96v256c0 53 43 96 96 96h256c53 0 96-43 96-96v-96c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
      </svg>
    </button>

    <div *ngIf="menu.ownerType === 'CHAIN'" class="menu__tags">
      <div class="menu__tag">{{ 'pages.menu.menuContent.shared' | translate }}</div>
    </div>

      <div *ngIf="menu.file" class="menu__row menu__original_file">
        <img class="icon" src="assets/icons/pdf.svg" alt="" />
        <a class="name" [href]="menu.file.url" [matTooltip]="menu.file.name" target="_blank">{{ menu.file.name }}</a>
      </div>

      <div class="menu__image" [ngClass]="{overlay: isEdit && menu.processedAt}">
        <div class="menu__image-container">
          <img *ngIf="menu.pages && menu.pages.length > 0 else loader" src="{{menuImage}}" alt="">
          <ng-template #loader>
            <mat-progress-spinner
              *ngIf="!menu.processingFailedAt"
              class="spinner"
              [color]="'primary'"
              [mode]="'indeterminate'"
              diameter="100"
            >
            </mat-progress-spinner>

            <div class="processing-error" *ngIf="menu.processingFailedAt">
              <mat-icon class="menu__error-icon">error_outline</mat-icon>
              <span>{{ 'pages.menu.menuContent.processingFailed' | translate }}</span>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="menu__row menu__row--vertical">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.icon' | translate }}: </div>
        <div class="menu__value menu__value--pattern">
          <img class="menu__value__img menu__value__img--large" *ngIf="menu.icon else noIcon" src="{{ menu.icon }}" alt="">
          <ng-template #noIcon>
            <img class="menu__value__img menu__value__img--large" src="assets/icons/noIcon.svg" alt="" />
            <span>{{ 'pages.menu.menuContent.noIcon' | translate }}</span>
          </ng-template>
        </div>
      </div>

      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.menuName' | translate }}: </div>
        <div class="menu__value">{{ menu.name }}</div>
      </div>

      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.happyHours' | translate }}: </div>
        <div class="menu__value">{{ (menu.happyHours ? 'common.yes' : 'common.no') | translate }}</div>
      </div>
      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.hide' | translate }}: </div>

        <div class="menu__value">{{ (menu.hidden ? 'common.yes' : 'common.no') | translate }}</div>
      </div>
  </div>
</ng-template>

