import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, finalize, find, map, mergeMap, pluck, share, timeout } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotifierService } from 'angular-notifier';
import { WebsocketService } from '../websocket/websocket.service';
import { OongaleeIntercom } from '../oongalee-intercom';
import { User } from '../../interfaces/user';
import { Chain } from '../../interfaces/chain';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { AdminService } from '../admin/admin.service';
import { UserRoles } from '../../shared/enums/userRoles';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user = new BehaviorSubject<User | null>(null);

  public chains = new BehaviorSubject<Chain[]>([]);
  public restaurants = new BehaviorSubject<IRestaurantInfo[]>([]);
  public areas$: Observable<'auth' | 'chains' | 'chain' | 'restaurant'>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notifier: NotifierService,
    private websocket: WebsocketService,
    private oongaleeIntercom: OongaleeIntercom,
    private adminService: AdminService,
  ) {
    this.websocket.connected$.pipe(
      filter((yes) => yes),
      filter(() => this.hasToken()),
      untilDestroyed(this)
    ).subscribe(() => {
      const token = this.getToken();

      if (token) {
        console.log('Auth: Sending an Authorization Token');
        this.websocket.auth(token);
      }
    });

    this.areas$ = combineLatest([this.chains, this.restaurants]).pipe(
      map(([chains, restaurants]) => {
        if (chains.length > 1) {
          return 'chains';
        }
        if (chains.length === 1) {
          return 'chain';
        }
        if (restaurants.length > 0) {
          return 'restaurant';
        }
        return 'auth';
      }),
      share()
    );
  }

  public login(email: string, password: string): Observable<any> {
    const subject$ = this.http.post('/users/login', {email, password});

    subject$.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.saveToken(data.meta.token);

      this.websocket.auth(data.meta.token);

      this.user.next(data.user);

      this.adminService.getAllRestaurants().subscribe((restaurants) => {
        this.restaurants.next(restaurants);
      });

      this.adminService.getAllChains().subscribe((chains) => {
        this.chains.next(chains);
      });

      if (this.oongaleeIntercom.connected) {
        this.oongaleeIntercom.call('push_notification.get_token');
        this.oongaleeIntercom.messages$.pipe(
          find((message) => message.event === 'push_notification.token'),
          pluck('data'),
          timeout(10000)
        ).subscribe((token) => {
          this.http.post('/users/push-notifications/subscribe', {
            token
          }).subscribe();
        });
      }


      this.router.navigate(['chains']);
    });

    return subject$;
  }

  logout(): Observable<null | boolean> {
    const unsubscribePushNotificationToken$ = this.oongaleeIntercom.messages$.pipe(
      find((message) => message.event === 'push_notification.token'),
      pluck('data'),
      timeout(10000),
      mergeMap((token) => this.http.post('/users/push-notifications/unsubscribe', {
        token
      }).pipe(
        map(() => true)
      ))
    );

    return of(this.oongaleeIntercom.connected).pipe(
      mergeMap((connected) => {

        if (connected) {
          this.oongaleeIntercom.call('push_notification.get_token');
          return unsubscribePushNotificationToken$;
        }

        return of(true);
      }),
      share(),
      catchError(() => of(false)),
      finalize(() => {
        this.websocket.close();
        this.removeToken();
        this.router.navigate(['/login']);
        this.user.next(null);
        this.chains.next([]);
        this.restaurants.next([]);
      })
    );
  }

  public fetchCurrentUser(): Observable<User> {
    return this.http.get<User>('/users/me');
  }

  isLoggined(): boolean {
    return this.hasToken();
  }

  public isSuperAdmin(structure: Chain | IRestaurantInfo): boolean {
    return this.isLoggined() && structure.meta.userRole === UserRoles.superAdmin;
  }

  public isAdmin(structure: Chain | IRestaurantInfo): boolean {
    return this.isLoggined() && [UserRoles.admin, UserRoles.superAdmin].includes(structure.meta.userRole);
  }

  public isWaiter(restaurant: IRestaurantInfo): boolean {
    return this.isLoggined() && restaurant.meta.userRole === UserRoles.waiter;
  }

  private saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  private removeToken(): void {
    localStorage.removeItem('token');
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public hasToken(): boolean {
    return localStorage.getItem('token') !== null;
  }

  public area(): 'auth' | 'chains' | 'chain' | 'restaurant' {
    if (this.chains.getValue().length > 1) {
      return 'chains';
    }

    if (this.chains.getValue().length === 1) {
      return 'chain';
    }

    if (this.restaurants.getValue().length > 0) {
      return 'restaurant';
    }

    return 'auth';
  }
}
