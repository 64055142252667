import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, map, share, switchMap, tap } from 'rxjs/operators';
import { WidgetUIConfiguration } from '../../../interfaces/widget-ui-config';

@UntilDestroy()
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent {

  @Input()
  public url: SafeUrl | null = null;

  @Input()
  public previewConfig = new BehaviorSubject<WidgetUIConfiguration | null>(null);

  @ViewChild('iframe')
  iframe: ElementRef<HTMLIFrameElement> | undefined;

  public initialized = false;
  public lighterColor: string | null = null;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public onIframeLoad(): void {
    const channel = new MessageChannel();
    channel.port1.start();

    this.iframe?.nativeElement.contentWindow?.postMessage('dwall-handshake', '*', [channel.port2]);

    const sendMessage = (event: string, data: any) => {
      channel.port1.postMessage({
        type: 'dwall-events',
        version: '0.0.1',
        event,
        data,
      });
    };
    const message$ = fromEvent<MessageEvent>(channel.port1, 'message').pipe(
      map((event) => JSON.parse(event.data)),
      share(),
    );

    // Init
    message$.pipe(
      filter((message) => message.method === 'init'),
      tap(() => {
        this.initialized = true;
      }),
      switchMap(() => this.previewConfig),
      filter((widgetUIConfig): widgetUIConfig is WidgetUIConfiguration => !!widgetUIConfig),
      debounceTime(100),
      untilDestroyed(this),
    ).subscribe((widgetUIConfig) => {
      sendMessage('demo.events', this.demoMessageTableInfo(widgetUIConfig));
    });

    // Ambient light on
    message$.pipe(
      filter((message) => message.method === 'ambient_light.on'),
      untilDestroyed(this),
    ).subscribe((message) => {
      const color = message.data.color;
      this.lighterColor = `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`;
      this.changeDetectorRef.detectChanges();
    });

    // Ambient light off
    message$.pipe(
      filter((message) => message.method === 'ambient_light.off'),
      untilDestroyed(this),
    ).subscribe(() => {
      this.lighterColor = null;
      this.changeDetectorRef.detectChanges();
    });
  }

  private demoMessageTableInfo(widgetUIConfig: WidgetUIConfiguration): any {
    const menus =  [
      {
        id: 1,
        name: 'Demo Menu 1',
        happyHours: false,
        hidden: false,
        ownerType: 'restaurant',
        createdAt: '2021-05-20T16:00:00.000Z',
        updatedAt: '2021-05-20T16:00:00.000Z',
        pages: [`/app/demo/menu.jpg`],
      },
      {
        id: 2,
        name: 'Demo Menu 2',
        happyHours: false,
        hidden: false,
        ownerType: 'restaurant',
        createdAt: '2021-05-20T16:00:00.000Z',
        updatedAt: '2021-05-20T16:00:00.000Z',
        pages: [`/app/demo/menu.jpg`],
      },
      {
        id: 3,
        name: 'Demo Happy Hour Menu 1',
        happyHours: true,
        hidden: false,
        ownerType: 'restaurant',
        createdAt: '2021-05-20T16:00:00.000Z',
        updatedAt: '2021-05-20T16:00:00.000Z',
        pages: [`/app/demo/menu.jpg`],
      },
      {
        id: 4,
        name: 'Demo Happy Hour Menu 2',
        happyHours: true,
        hidden: false,
        ownerType: 'restaurant',
        createdAt: '2021-05-20T16:00:00.000Z',
        updatedAt: '2021-05-20T16:00:00.000Z',
        pages: [`/app/demo/menu.jpg`],
      },
    ];

    return {
      type: 'tableInfo',
      data: {
        tableId: 1,
        tableName: 'Demo Table',
        invoiceQRCodeUrl: '/app/demo/qr-code.jpg',
        language: 'en-CA',
        wifiName: 'demo_5G',
        wifiPassword: 'demo1234',
        widgetUIConfig,
        happyMenuPeriods: [],
        deviceWorkSchedule: [],
        clickableMediaConfig: null,
        specials: [],
        menus,
        adRunEvery: 3,
        integrations: [],
        features: {
          withCallWaiter: true,
          withCallWaiterToPay: true,
          withCallWaiterToRepeat: true,
        }
      },
    };
  }

}
