import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IChainMenu } from '../../../interfaces/menu';
import { isFormChanged } from '../../../shared/function';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { MatSelectChange } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'app-chain-menu',
  templateUrl: './chain-menu.component.html',
  styleUrls: ['./chain-menu.component.scss'],
})
export class ChainMenuComponent implements OnInit {

  @ViewChild('inputFile') inputMenu!: ElementRef<HTMLInputElement>;
  private edit = false;
  public menuData!: IChainMenu;
  public restaurants!: IRestaurantInfo[];

  @Input() set menu(menu: IChainMenu) {
    this.menuData = menu;
    this.form.setValidators(
      isFormChanged({
        name: menu.name,
        happyHours: menu.happyHours,
        hidden: menu.hidden,
      })
    );
    this.attachedRestaurants = this.getAttachedRestaurants(this.restaurants, menu);
  }

  @Input() set isEdit(value: boolean) {
    this.edit = value;
    this.resetInputFiles();
  }
  @Input() chainId!: number;
  @Input() isMainMenu = true;
  @Input() set allRestaurants(restaurants: IRestaurantInfo[]) {
    this.restaurants = restaurants;
    this.attachedRestaurants = this.getAttachedRestaurants(restaurants, this.menuData);
  }
  menuFile: File | null = null;
  iconFile: File | null = null;
  isUpdating = false;

  // tslint:disable-next-line:adjacent-overload-signatures
  get menu(): IChainMenu {
    return this.menuData;
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get isEdit(): boolean {
    return this.edit;
  }

  form = new FormGroup({});
  attachedRestaurants: IRestaurantInfo[] | null = null;

  constructor(private menuState: MenuStoreService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(this.menuData.name, [Validators.required, Validators.minLength(3)]),
      menuFile: new FormControl(null),
      iconFile: new FormControl( null ),
      happyHours: new FormControl(this.menuData.happyHours),
      hidden: new FormControl(this.menuData.hidden),
      restaurants: new FormControl({
        value: this.menuData.attachedToAll ? null : this.menuData?.attachedRestaurantIds,
        disabled: this.menuData.attachedToAll
      }),
      attachedToAll: new FormControl(this.menuData.attachedToAll),
    });

    this.form.get('attachedToAll')?.valueChanges.subscribe((checked) => {
      this.form.get('restaurants')?.reset({
        value: checked ? null : this.menuData?.attachedRestaurantIds,
        disabled: checked
      });
    });
  }

  update(data: {
    id: number;
    values: {
      name: string;
      language: string | null;
      happyHours: boolean;
      hidden: boolean;
      menuFile: File | null;
      restaurants: number[] | null,
      attachedToAll: boolean,
      iconFile?: File;
    };
  }): void {
    this.isUpdating = true;
    const menuType = this.isMainMenu ? 'mainMenu' : 'happyMenu';
    let updatedRestaurants!: number[] | null;

    if (data.values.attachedToAll) {
      updatedRestaurants = [];
    } else if ((!data.values.attachedToAll && data.values.restaurants?.length === 0) || this.isNullInArray(data.values.restaurants)) {
      updatedRestaurants = null;
    } else {
      updatedRestaurants = data.values.restaurants;
    }

    this.menuState
      .updateChainMenu(data.id, this.chainId, menuType, updatedRestaurants, { ...data.values })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isUpdating = false;
        },
        () => (this.isUpdating = false)
      );
  }

  public isNullInArray(restaurants: number[] | null): boolean {
    return restaurants?.length === 1 && restaurants[0] === null;
  }

  chainMenuDelete(chainId: number, menuId: number): void {
    this.isUpdating = true;
    this.menuState
      .removeChainMenu(chainId, menuId)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => (this.isUpdating = false),
        () => (this.isUpdating = false)
      );
  }

  onMenuFileChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.menuFile = file[0];
      this.form.get('menuFile')?.setValue(file[0]);
      this.form.get('menuFile')?.markAsDirty();
    } else {
      this.form.get('menuFile')?.setValue(null);
      this.form.get('menuFile')?.markAsPristine();
    }
    this.form.updateValueAndValidity();
  }

  onIconFileChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.iconFile = file[0];
      this.form.get('iconFile')?.setValue(file[0]);
      this.form.get('iconFile')?.markAsDirty();
    } else {
      this.form.get('iconFile')?.setValue(null);
      this.form.get('iconFile')?.markAsPristine();
    }
    this.form.updateValueAndValidity();
  }

  resetInputFiles(): void {
    setTimeout(() => {
      this.inputMenu.nativeElement.value = '';
    }, 0);
    this.menuFile = null;
    this.iconFile = null;
    this.form.patchValue({ menuFile: null });
    this.form.patchValue({ iconFile: null });
  }

  public setSelectedRestaurants(event: MatSelectChange): void {
    const selectedOptions = event.value as number[];
  }

  private getAttachedRestaurants(restaurants: IRestaurantInfo[], menu: IChainMenu): IRestaurantInfo[] | null {

    if (!restaurants || !menu) {
      return null;
    } else if (menu.attachedRestaurantIds){
        return restaurants.filter(r => menu.attachedRestaurantIds?.some(id => id === r.id));
    }
    return null;
  }
}
