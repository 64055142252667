import { Component, OnInit } from '@angular/core';
import { IChainMenu } from '../../../interfaces/menu';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddChainMenuComponent } from '../../../components/modals/add-chain-menu/add-chain-menu.component';
import { AdminService } from '../../../services/admin/admin.service';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { pluck, tap } from 'rxjs/operators';
import { Chain } from '../../../interfaces/chain';
import { ActivatedRoute } from '@angular/router';
import { WebsocketService } from '../../../services/websocket/websocket.service';
import { AuthService } from '../../../services/auth/auth.service';
import { PageEvent } from '@angular/material/paginator/paginator';

@UntilDestroy()
@Component({
  selector: 'app-chain-menu-page',
  templateUrl: './chain-menu-page.component.html',
  styleUrls: ['./chain-menu-page.component.scss']
})
export class ChainMenuPageComponent implements OnInit {
  public chain!: Chain;
  public isEdit = false;
  public isLoading = false;
  public chainId!: string;
  public tabIndex = 0;
  public allRestaurants: IRestaurantInfo[] = [];
  public editable = false;

  public menus: IChainMenu[] = [];

  public searchQuery = this.activateRoute.snapshot.queryParams.s || '';
  public pageSize = 10;
  public pageSizeOptions = [10, 30, 60];
  public pageIndex = 0;

  constructor(
    private activateRoute: ActivatedRoute,
    private notify: NotifierService,
    private dialog: MatDialog,
    private menuStore: MenuStoreService,
    private adminService: AdminService,
    private websocket: WebsocketService,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.editable = !this.auth.isViewer(chain);
        this.chain = chain;
        this.initData();
      }),
      untilDestroyed(this),
    ).subscribe();
    this.initWebsocket();
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        this.menuStore.fetchChainMenu(this.chain.id, message.data.id);
      }
    });
  }

  public getRestaurants(chainId: number): void {
    this.adminService.getRestaurantsByChainId(chainId).subscribe((restaurants) => {
      this.allRestaurants = restaurants;
    });
  }

  public initData(): void {
    this.menuStore.fetchChainMenus({
      chainId: this.chain.id,
      menuType: 'mainMenu',
    });

    this.menuStore.getChainMenus$.pipe(
      untilDestroyed(this)
    ).subscribe((menus) => {
      this.menus = menus.filter((menu) => this.menuFilter(menu, this.searchQuery));
    });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => {
      this.isLoading = load;
    });

    this.getRestaurants(this.chain.id);
  }

  public createChainMenu(): void {
    this.dialog.open(AddChainMenuComponent, {
      data: { chainId: this.chain.id, happyHours: false, allRestaurants: this.allRestaurants },
    });
  }

  restaurantFilter(restaurant: IRestaurantInfo, query: string): boolean {
    return (
      restaurant.name.toLowerCase().includes(query) ||
      restaurant.email.toLowerCase().includes(query)
    ) === true;
  }

  menuFilter(menu: IChainMenu, query: string): boolean {
    if (query.length === 0) {
      return true;
    }

    const querySanitized = query.trim().toLowerCase();

    return (
      menu.name.toLowerCase().includes(querySanitized) ||
      menu.file?.name.toLowerCase().includes(querySanitized) ||
      (querySanitized === 'hidden' && menu.hidden === true) ||
      (querySanitized === 'no icon' && !menu.icon) ||
      (querySanitized === 'no restaurants' && (
        !menu.attachedToAll && (
          !menu.attachedRestaurantIds ||
          menu.attachedRestaurantIds.length === 0 ||
          menu.attachedRestaurantIds[0] === null
        )
      )) ||
      (
        (menu.attachedToAll && this.allRestaurants.some((r) => this.restaurantFilter(r, querySanitized))) ||
        menu.attachedRestaurantIds?.some((id) => {
          const restaurant = this.allRestaurants.find((r) => r.id === id);

          if (!restaurant) {
            return false;
          }

          return this.restaurantFilter(restaurant, querySanitized);
        })
      )
    ) === true;
  }

  onSearch(search: string): void {
    this.searchQuery = search;
    this.pageIndex = 0;

    this.menuStore.getChainMenus$.pipe(
      untilDestroyed(this)
    ).subscribe((menus) => {
      this.menus = menus.filter((menu) => this.menuFilter(menu, search));
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    document.body.scrollIntoView({ behavior: 'auto' });
  }

}
