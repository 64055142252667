import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import {filter, map, share, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

interface IFileIntentResponse {
  fileId: number;
  url: string;
  expiresAt: string;
}

export interface IFileUploadProgress {
  type: 'progress';
  progress: number;
  intent: IFileIntentResponse;
}

export interface IFileUploadSuccess {
  type: 'success';
  intent: IFileIntentResponse;
}

export type IFileUploadEvent = IFileUploadProgress | IFileUploadSuccess;

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private http: HttpClient,
  ) {}

  public upload(file: File): Observable<IFileUploadEvent> {
    return this.fileIntent(file).pipe(
      switchMap((fileIntent) => {
        return  this.uploadFile(file, fileIntent.url).pipe(
            map((event): IFileUploadEvent => {
              if (event.type === HttpEventType.UploadProgress) {
                return {
                  type: 'progress',
                  progress: Math.round((100 * event.loaded) / (event.total ?? 0)),
                  intent: fileIntent
                };
              }
              return {
                type: 'success',
                intent: fileIntent,
              };
            })
          );
      }),
      share()
    );
  }

  public fileIntent(file: File): Observable<IFileIntentResponse> {
    return this.http.post<IFileIntentResponse>('/files/upload-intent', {
      name: file.name,
      size: file.size,
      mimeType: file.type
    });
  }

  private uploadFile(file: File, url: string): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({'Content-Type': file.type});

    return this.http.put(url, file, {
      headers,
      observe: 'events',
      reportProgress: true
    }).pipe(
      filter((event) => event.type === HttpEventType.Response)
    );
  }

  public getFile(file: {
    id: number;
    name: string;
    url: string;
  }): void {
    this.http.get(file.url, { responseType: 'blob' }).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
