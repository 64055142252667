import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { forkJoin, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { IChainMenu } from '../../../interfaces/menu';
import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { arraysEqual } from '../../../shared/function';

@UntilDestroy()
@Component({
  selector: 'app-edit-chain-menu',
  templateUrl: './edit-chain-menu.component.html',
  styleUrls: ['./edit-chain-menu.component.scss'],
})
export class EditChainMenuComponent {
  public selected = 0;
  public languages = [
    { value: 'en-CA', viewValue: 'English' },
    { value: 'fr-CA', viewValue: 'French' },
    { value: null, viewValue: 'Not Set' },
  ];
  public menus = this.data.menusGroup;
  public isMainMenu = this.data.isMainMenu;
  public happyHours = this.data.menusGroup?.[0].happyHours;
  public form: FormGroup = this.createMenuFormGroup();
  public isUpdating = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chainId: number;
      isMainMenu: boolean;
      menusGroup: IChainMenu[];
      restaurants: IRestaurantInfo[];
    },
    private menuState: MenuStoreService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.form.get('toAll')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((value: boolean) => {
      if (value) {
        this.form.get('restaurants')?.reset({
          value: [],
          disabled: true,
        });
      } else {
        this.form.get('restaurants')?.reset({
          value: null,
          disabled: false,
        });
      }
    });
  }

  private createMenuFormGroup(): FormGroup {
    return new FormGroup({
      happyHours: new FormControl(this.data.menusGroup[0].happyHours),
      restaurants: new FormControl({
        value: this.data.menusGroup[0].attachedToAll ? null : this.data.menusGroup[0]?.attachedRestaurantIds,
        disabled: this.data.menusGroup[0].attachedToAll
      }),
      toAll: new FormControl(this.data.menusGroup[0].attachedToAll),
      menusFormArray: new FormArray (
        this.data.menusGroup.map(menu => this.generateMenuFormArray(menu))
      )
    });
  }

  private generateMenuFormArray(menu: IChainMenu): FormGroup {
    return new FormGroup({
      id: new FormControl(menu.id),
      name: new FormControl(menu.name, [Validators.required, Validators.minLength(3)]),
      language: new FormControl(menu.language),
      hidden: new FormControl(menu.hidden),
      menuFile: new FormControl(menu.file || null, [Validators.required]),
      iconFile: new FormControl(menu.icon || null)
    });
  }

  get formArray(): FormArray {
    return this.form.get('menusFormArray') as FormArray;
  }
  get menusControls(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  public menuPreviewUrl(index: number): string {
      return this.menus[index]?.pages?.[0] ?? '';
  }

  public isLanguageAvailable(language: string | null, currentIndex: number): boolean {
    const selectedLanguages = this.menusControls
      .map((menu, index) => index !== currentIndex ? menu.controls.language.value : null)
      .filter(lang => lang !== null);

    return !selectedLanguages.includes(language) && !(selectedLanguages.length > 0 && language === null);
  }

  public addTranslate(): void {
    this.formArray.push(new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      language: new FormControl(null, [Validators.required]),
      hidden: new FormControl(false),
      happyHours: new FormControl(this.happyHours),
      menuFile: new FormControl(null, [Validators.required]),
      iconFile: new FormControl(null)
    }));

    this.selected = this.formArray.length - 1;
  }

  public removeTranslate(index: number, id: number): void {
    if (id) {
      const title = this.translate.instant('pages.menu.modal.delete');
      const message = this.translate.instant('pages.menu.messages.confirmDelete');
      const confirmationModal = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message,
          title
        },
        panelClass: 'confirmation-modal'
      });

      confirmationModal.afterClosed().pipe(
        filter(Boolean),
        untilDestroyed(this)
      ).subscribe((): void => {
        this.delete(this.data.chainId, id, index);
      });
    } else {
      (this.form.get('menusFormArray') as FormArray).removeAt(index);
      this.selected = index;
    }
  }

  update(): void {
    this.isUpdating = true;

    const [menuOriginalData, ...menuTranslatedData] = this.menusControls;
    const originalMenuId = this.data.menusGroup[0].id;
    const menuType = this.isMainMenu ? 'mainMenu' : 'happyMenu';
    let updatedRestaurants!: number[] | null;

    if (this.form.value.toAll) {
      updatedRestaurants = [];
    } else if (
      (!this.form.value.toAll && this.form.value.restaurants?.length === 0) ||
      this.isNullInArray(this.form.value.restaurants)
    ) {
      updatedRestaurants = null;
    } else {
      updatedRestaurants = this.form.value.restaurants;
    }

    const updateChainMenu = (menuData: any) => {
      return this.menuState.updateChainMenu(
        menuData.value.id,
        this.data.chainId,
        menuType,
        updatedRestaurants,
        {
          name: menuData.value.name,
          language: menuData.value.language,
          happyHours: this.happyHours,
          hidden: menuData.value.hidden,
          menuFile: menuData.value.menuFile,
          iconFile: menuData.value.iconFile,
        }
      );
    };


    const addChainMenu = (menuData: any, originalId: number | null) =>
      this.menuState.addChainMenu(
        this.data.chainId,
        menuData.value.name,
        menuData.value.language,
        this.happyHours,
        menuData.value.hidden,
        menuData.value.menuFile,
        updatedRestaurants,
        originalId,
        menuData.value.iconFile
      );

    if (this.isChangedOriginal()) {
      updateChainMenu(menuOriginalData).pipe(
          switchMap((originalMenu) => {
            if (menuTranslatedData.length > 0) {
              return forkJoin(
                  menuTranslatedData.map((menuTranslated) => {
                        if (menuTranslated.value.id) {
                          return updateChainMenu(menuTranslated);
                        }

                        return addChainMenu(menuTranslated, originalMenu.id);
                      }
                  )
              );
            }
            return of(originalMenu);
          }),
          untilDestroyed(this)
      ).subscribe({
        next: () => {
          this.isUpdating = false;
          this.dialog.closeAll();
        },
        error: () => {
          this.isUpdating = false;
          this.dialog.closeAll();
        }
      });
    } else if (menuTranslatedData.length > 0 && this.isChangedTranslations()) {
        forkJoin(
            menuTranslatedData.map((menuTranslated) => {
                  if (menuTranslated.value.id) {
                    return updateChainMenu(menuTranslated);
                  }

                  return addChainMenu(menuTranslated, originalMenuId);
                }
            )
        ).subscribe({
          next: () => {
            this.isUpdating = false;
          },
          error: () => {
            this.isUpdating = false;
          }
        });
    }
  }

  public isNullInArray(restaurants: number[] | null): boolean {
    return restaurants?.length === 1 && restaurants[0] === null;
  }

  delete(chainId: number, menuId: number, index: number): void {
    this.isUpdating = true;
    this.menuState
      .removeChainMenu(chainId, menuId)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          (this.form.get('menusFormArray') as FormArray).removeAt(index);

          if ((this.form.get('menusFormArray') as FormArray).length === 0) {
            this.dialog.closeAll();
          } else {
            this.selected = index;
            this.isUpdating = false;
          }
        }
        ,
        () => {
          this.isUpdating = false;
          this.dialog.closeAll();
        }
      );
  }

  public isChangedOriginal(): boolean {
    const menuOriginalData = this.menusControls[0];
    const menu = this.data.menusGroup[0];

    return (
      menuOriginalData.value.name !== menu.name ||
      menuOriginalData.value.language !== menu.language ||
      menuOriginalData.value.hidden !== menu.hidden ||
      menuOriginalData.value.menuFile !== menu.file ||
      menuOriginalData.value.iconFile !== menu.icon ||
      this.form.value.toAll !== menu.attachedToAll ||
      !arraysEqual(this.form.value.restaurants, menu.attachedRestaurantIds)
    );
  }

  public isChangedTranslations(): boolean {
    const menuTranslationData = this.menusControls.slice(1);
    const translation = this.data.menusGroup.slice(1);

    if (menuTranslationData.length !== translation.length) {
      return true;
    }

    return menuTranslationData.some((menuControl, index) => {
      const menu = translation[index];

      return (
        menuControl.value.name !== menu.name ||
        menuControl.value.language !== menu.language ||
        menuControl.value.hidden !== menu.hidden ||
        menuControl.value.menuFile !== menu.file ||
        menuControl.value.iconFile !== menu.icon
      );
    });
  }
}
