import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin/admin.service';
import { AnyIntegration, IRestaurantInfo } from '../../interfaces/restaurant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, pluck, tap} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth/auth.service';
import { nullIfEmptyValidator, wifiNameAndPasswordValidator } from '../../shared/custom-validators';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddIntegrationComponent } from '../../components/modals/add-integration/add-integration.component';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { EditIntegrationComponent } from '../../components/modals/edit-integration/edit-integration.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { NewPeriod, Period } from '../../interfaces/period';

const compareObjects = (initialForm: FormGroup) =>
  Object.entries(initialForm.value)
    .filter(([key, value]) => initialForm?.get(key)?.dirty)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

@UntilDestroy()
@Component({
  selector: 'app-restaurant-account-page',
  templateUrl: './restaurant-account-page.component.html',
  styleUrls: ['./restaurant-account-page.component.scss']
})
export class RestaurantAccountPageComponent implements OnInit {

  public restaurantInfo: FormGroup = this.generateForm();
  public loading = false;
  public isAvailable = false;
  public restaurantId!: number;
  public restaurantInfoData!: IRestaurantInfo;
  public integrations: AnyIntegration[] = [];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public publicUrl: string | null = null;
  public workingHours: Period[] = [];
  public editable = false;
  public isEditPeriods = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private activateRoute: ActivatedRoute,
    private notifier: NotifierService,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private restaurant: RestaurantService,
    public auth: AuthService,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.editable = !this.auth.isViewer(restaurant);
        this.restaurantId = restaurant.id;
        this.publicUrl = restaurant.publicUrl;
        this.getRestaurantById(restaurant.id);
        this.getAllIntegration();
        this.isAvailable = this.auth.isSuperAdmin(restaurant);
      }),
      untilDestroyed(this),
    ).subscribe(() => {
    });

    this.restaurant.getWorkingHours(this.restaurantId).pipe(
      untilDestroyed(this),
    ).subscribe((workHours: Period[]) => {
      this.workingHours = workHours;
    });
  }

  private generateForm(): FormGroup {
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~[\\]+=\\-/,]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    const phoneRegex = new RegExp(/^\+?\d+$/);
    return this.formBuilder.group({
      name: [''],
      email: [''],
      address: [''],
      contactPhone: ['', [Validators.pattern(phoneRegex)]],
      wifiName: ['', [Validators.maxLength(50)]],
      wifiPassword: ['', [Validators.maxLength(64)]],
      language: [''],
      dwallGroupId: [''],
      province: [''],
      adRunEvery: ['', [Validators.maxLength(255)]],
      adVistarMediaApiKey: ['', [Validators.maxLength(255)]],
      adVistarMediaNetworkId: ['', [Validators.maxLength(255)]],
      adVistarMediaVenueId: ['', [Validators.maxLength(255)]],
      adVastTagUrl: [null, [Validators.pattern(urlRegex), nullIfEmptyValidator]],
    }, {validators: [wifiNameAndPasswordValidator]});
  }

  getRestaurantInfo(restaurant: IRestaurantInfo): void {
    this.restaurantInfo.patchValue({
      name: restaurant?.name,
      email: restaurant?.email,
      address: restaurant?.address,
      contactPhone: restaurant?.contactPhone,
      dwallGroupId: restaurant?.dwallGroupId,
      province: restaurant?.province,
      wifiName: restaurant?.wifiName,
      wifiPassword: restaurant?.wifiPassword,
      language: restaurant?.language,
      adRunEvery: restaurant?.adRunEvery,
      adVastTagUrl: restaurant?.adVastTagUrl,
      adVistarMediaApiKey: restaurant?.adVistarMediaApiKey,
      adVistarMediaNetworkId: restaurant?.adVistarMediaNetworkId,
      adVistarMediaVenueId: restaurant?.adVistarMediaVenueId,
    });
  }

  updateRestaurantInfo(form: FormGroup): void {
    this.loading = true;

    const changedValues: { [key: string]: any } = compareObjects(form);

    if (changedValues.hasOwnProperty('contactPhone')) {
      changedValues.contactPhone = changedValues.contactPhone.toString();
    }

    if (changedValues.hasOwnProperty('province') && changedValues.province === '') {
      changedValues.province = null;
    }

    if (changedValues.hasOwnProperty('dwallGroupId') && changedValues.dwallGroupId === '') {
      changedValues.dwallGroupId = null;
    }

    if (changedValues.hasOwnProperty('wifiName') && changedValues.wifiName === '') {
      changedValues.wifiName = null;
    }

    this.adminService.updateRestaurantInfo({ id: this.restaurantInfoData?.id, ...changedValues })
      .subscribe((restaurant) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.infoChangeSuccess'));
      this.loading = false;
      this.restaurantInfo.reset(restaurant);
    }, () => {
      this.loading = false;
    });
  }

  public getRestaurantById(restaurantId: number): void {
    this.adminService.getRestaurantInfo(restaurantId).subscribe((restaurant) => {
      this.restaurantInfoData = restaurant;
      this.getRestaurantInfo(restaurant);
    });
  }

  public confirmToRemoveRestaurant(): void {
    const title = this.translate.instant('pages.restaurantAccount.deleteRestaurantTitle');
    const message = this.translate.instant('pages.restaurantAccount.deleteRestaurant');

    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.removeRestaurant(this.restaurantInfoData?.id);
    });
  }

  public removeRestaurant(restaurantId: number): void {
    this.adminService.removeRestaurant(restaurantId).subscribe((data) => {

      const successMessage = `${this.translate.instant('pages.restaurantAccount.removedSuccessfullyPart1')} '${this.restaurantInfoData.name}' ${this.translate.instant('pages.restaurantAccount.removedSuccessfullyPart2')}`;
      this.notifier.notify('success', successMessage);

      this.router.navigate(['/chains', this.restaurantInfoData.chainId]);
    });
  }

  public createPeriod(period: NewPeriod): void {
    this.restaurant.createWorkingHoursPeriod(this.restaurantId, period).pipe(
      untilDestroyed(this)
    ).subscribe((response: Period) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.addedPeriod'));
      this.workingHours.push(response);
      this.sortPeriods();
    });
    this.sortPeriods();
  }

  public updatePeriod(period: {id: number; startDay: number; startTime: string; endDay: number; endTime: string }): void {
    this.restaurant.updateWorkingHoursPeriod(this.restaurantId, period).pipe(
      untilDestroyed(this)
    ).subscribe((response: Period) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.updatePeriod'));
      this.workingHours = this.workingHours.map((p) => {
        return p.id !== response.id ? p : response;
      });
      this.sortPeriods();
    });
  }

  public deletePeriod(id: number): void {
    this.restaurant.deleteWorkingHours(this.restaurantId, id).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.deletedPeriod'));
      this.workingHours = this.workingHours.filter((p) => p.id !== id);
      this.sortPeriods();
    });
  }

  private sortPeriods(): void {
    this.workingHours.sort((a, b) => {

      if (a.startDay !== b.startDay) {
        return a.startDay - b.startDay;
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }

  public downloadQr(): void {
    const parentElement = parent.document.getElementById('qrElement');
    const svgElement = parentElement?.querySelector('canvas')?.toDataURL('image/png');

    if (svgElement) {

      const blobData = this.convertBase64ToBlob(svgElement);

      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.download = this.restaurantInfoData.name + 'PublicMenu';
      link.click();

      window.URL.revokeObjectURL(url);
    }
  }

  private convertBase64ToBlob(Base64Image: string): Blob {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }

  public addIntegration(): void {
    const addIntegration = this.matDialog.open(AddIntegrationComponent, {
      data: {
        restaurantId: this.restaurantId,
      },
    });

    addIntegration.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data: any) => {
      this.integrations = [...this.integrations, data];
    });
  }
  public getAllIntegration(): void {
    this.restaurant.getAllIntegration(this.restaurantId).subscribe((integrations: AnyIntegration[]) => {
      this.integrations = integrations;
    });
  }

  public editIntegration(integrationId: string): void {
    const editIntegration = this.matDialog.open(EditIntegrationComponent, {
      data: {
        restaurantId: this.restaurantId,
        integrationId
      },
    });

    editIntegration.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data: any) => {
      if (typeof data === 'string') {
        this.integrations = this.integrations
          .filter(integration => integration.id !== data);
      } else {
        this.integrations = this.integrations
          .map(integration => {
            return (integration.id === data.id ? data : integration);
          });
      }
    });
  }

  copyLink(): void {
    this.clipboard.copy(this.publicUrl as string);
    this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.linkCopied'));
  }
}
