import { Component, OnInit } from '@angular/core';
import { AddRestaurantComponent } from '../../components/modals/add-restaurant/add-restaurant.component';
import { AdminService } from '../../services/admin/admin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Chain } from '../../interfaces/chain';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { BehaviorSubject, combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-super-admin',
  templateUrl: './restaurants-page.component.html',
  styleUrls: ['./restaurants-page.component.scss']
})
export class RestaurantsPageComponent implements OnInit{

  public allRestaurants!: IRestaurantInfo[];
  public restaurants$ = new BehaviorSubject<IRestaurantInfo[]>([]);
  public chainId!: number;
  public currentChain!: Chain;
  public isEdited = false;
  public searchTerm$ = new BehaviorSubject<string>('');
  private lastRestaurantId: string | null = null;

  public filteredRestaurants$ = combineLatest([this.restaurants$, this.searchTerm$]).pipe(
    map(([restaurants, searchTerm]) => {
      if (searchTerm.length <= 0) {
        return restaurants;
      }
      return restaurants.filter(rest => rest.name.toLowerCase().includes(searchTerm.toLowerCase()));
    })
  );

  constructor(
    private activateRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getCurrentChain();

    this.activateRoute.queryParams.pipe(
      filter(params => params.s !== undefined),
      tap(params => {
        const searchParam = params.s || '';
        this.searchTerm$.next(searchParam);
      }),
      untilDestroyed(this)
    ).subscribe();

    this.filteredRestaurants$.pipe(
      switchMap(() => this.activateRoute.fragment),
      filter(fragment => !!fragment),
      tap(fragment => {
        this.lastRestaurantId = fragment;
        if (this.lastRestaurantId) {
          this.scrollToElement(this.lastRestaurantId as string);
          this.router.navigate([], {
            fragment: undefined,
            queryParamsHandling: 'preserve',
            replaceUrl: true
          });
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private getCurrentChain(): void {
    this.activateRoute.parent?.data.pipe(
      map(data => data.chain),
      tap(chain => {
        this.currentChain = chain;
      }),
      switchMap(chain => this.adminService.getRestaurantsByChainId(chain.id)),
      tap(restaurants => {
        this.restaurants$.next(restaurants.reverse());
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  private scrollToElement(fragment: string): void {
    setTimeout(() => {
      const element = document.getElementById(fragment);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        element.classList.add('highlight');
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 2000);
      }

    }, 500);
  }

  get isEmpty(): boolean {
    return this.restaurants$.value.length === 0;
  }

  public openAddRestaurant(): void {
    const restaurantModal = this.matDialog.open(AddRestaurantComponent, {
      data: {
        chainId: this.currentChain.id
      }
    });

    restaurantModal.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurants.addRestaurantSuccessfully'));
      this.restaurants$.next([data as IRestaurantInfo, ...this.restaurants$.value]);
    });
  }

  public searchRestaurants(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
    sessionStorage.setItem('chain.' + this.currentChain.id + '.restaurants', searchTerm);
  }
}
