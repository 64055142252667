import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, pluck, tap } from 'rxjs/operators';
import { AdminService } from '../../services/admin/admin.service';
import { NotifierService } from 'angular-notifier';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { IMenu, IMenuGroup } from '../../interfaces/menu';
import { MenuStoreService } from '../../services/menu/menu-store.service';
import { AddMenuComponent } from '../../components/modals/add-menu/add-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { NewPeriod } from '../../interfaces/period';
import { Period} from '../../interfaces/period';
import { AuthService } from '../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-happy-hours-page',
  templateUrl: './happy-hours-page.component.html',
  styleUrls: ['./happy-hours-page.component.scss']
})
export class HappyHoursPageComponent implements OnInit {

  public env = environment;

  public menuUrl: string | undefined;
  public data: any;
  public file: any;
  public isLoading = false;
  public happyHourPeriods: Period[] = [];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public isEdit = false;
  public isEditPeriods = false;
  public restaurantInfo!: IRestaurantInfo;
  public menus: IMenuGroup[] = [];
  public isMenuLoading = false;
  public editable = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notify: NotifierService,
    private translate: TranslateService,
    private restaurant: RestaurantService,
    private admin: AdminService,
    private menuStore: MenuStoreService,
    private websocket: WebsocketService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.editable = !this.auth.isViewer(restaurant);
        this.restaurantInfo = restaurant;
        this.getRestaurantInfo(restaurant.id);
        this.initMenu();
      }),
      untilDestroyed(this),
    ).subscribe();

    this.restaurant.getAllHappyHoursPeriods(this.restaurantInfo.id).pipe(
      untilDestroyed(this),
    ).subscribe((period) => {
      this.happyHourPeriods = period;
    });

    this.initWebsocket();
  }

  initMenu(): void {
    this.menuStore.fetchMenus({restaurantId: this.restaurantInfo.id, menuType: 'happyMenu'});

    this.menuStore.getMenus$.
    pipe(untilDestroyed(this))
      .subscribe((menus) => {
        this.menus = menus;
      });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => this.isMenuLoading = load);
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        message.data.attachedRestaurantIds.map((id: number) => {
          if (id === this.restaurantInfo.id) {
            this.menuStore.fetchMenu(this.restaurantInfo.id, message.data.id);
          }
        });
      }
    });
  }

  createMenu(): void {
    this.dialog.open(AddMenuComponent, {
      data: {restaurantId: this.restaurantInfo.id, happyHours: true}
    });
  }

  getRestaurantInfo(id: number): void {
    this.admin.getRestaurantInfo(id).subscribe((response: IRestaurantInfo) => {

      if (response.happyMenuUrl) {
        this.http.get(this.env.apiUrl + response.happyMenuUrl).pipe(
          filter((data: any) => {
            return !!(data.pages) && data.pages.length > 0;
          })
        ).subscribe((data: any) => {
          this.menuUrl = this.env.apiUrl + data.pages[0] + '?t=' + moment().unix();
        });
      }
    });
  }
  public createPeriod(period: NewPeriod): void {
    this.restaurant.createHappyHoursPeriod(this.restaurantInfo.id, period).pipe(
      untilDestroyed(this)
    ).subscribe((response: Period) => {
      this.notify.notify('success', this.translate.instant('pages.happyHours.messages.addedPeriod'));
      this.happyHourPeriods.push(response);
      this.sortPeriods();
    });
    this.sortPeriods();
  }

  private sortPeriods(): void {
    this.happyHourPeriods.sort((a, b) => {

      if (a.startDay !== b.startDay) {
        return a.startDay - b.startDay;
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }

  public deletePeriod(id: number): void {
    this.restaurant.deleteHappyHoursPeriod(this.restaurantInfo.id, id).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.notify.notify('success', this.translate.instant('pages.happyHours.messages.deletedPeriod'));
      this.happyHourPeriods = this.happyHourPeriods.filter((p) => p.id !== id);
      this.sortPeriods();
    });
  }

  public updatePeriod(period: {id: number; startDay: number; startTime: string; endDay: number; endTime: string }): void {
    this.restaurant.updateHappyHoursPeriod(this.restaurantInfo.id, period.id, period).pipe(
      untilDestroyed(this)
    ).subscribe((response: Period) => {
      this.notify.notify('success', this.translate.instant('pages.happyHours.messages.updatePeriod'));
      this.happyHourPeriods = this.happyHourPeriods.map((p) => {
        return p.id !== response.id ? p : response;
      });
      this.sortPeriods();
    });
  }
}
