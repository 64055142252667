export const environment = {
  name: 'dev',
  production: false,
  apiUrl: 'https://oongalee-exscuseme-srv-dev.r2.danavero.com',
  socketApi: 'wss://oongalee-exscuseme-srv-dev.r2.danavero.com',
  widgetUrl: 'https://oongalee-exscuseme-widget-dev.r2.danavero.com?startupMode=demo',
  sentry: {
    dsn: '',
  },
};
