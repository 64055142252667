<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.menu.modal.editMenu' | translate }}</h1>
  <form [formGroup]="form">
    <mat-tab-group
      [selectedIndex]="selected"
      (selectedIndexChange)="selected=($event)"
    >
      <mat-tab *ngFor="let menu of menusControls; index as i">
        <ng-template matTabLabel>
          {{ 'pages.menu.modal.language.'+menu.controls.language.value | translate }}
          <button
            class="btn btn-delete__small"
            *ngIf="this.formArray.length !== 1"
            (click)="removeTranslate(i, menu.controls.id?.value)"
          >
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </ng-template>

        <ng-container [formGroup]="menu">
          <div class="menu_params">
            <mat-form-field class="login-wrap__text">
              <mat-label>Language</mat-label>
              <mat-select formControlName="language" [value]="menu.controls.language.value">
                <mat-option
                  *ngFor="let lang of languages"
                  [value]=lang.value
                  [disabled]="!isLanguageAvailable(lang.value, i)"
                >
                  {{ 'pages.menu.modal.language.'+lang.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="login-wrap__text">
              <mat-label>{{ 'pages.menu.modal.name' | translate }}</mat-label>
              <input matInput appFormInputTrim type="text" formControlName="name" />
              <mat-error *ngIf="menu.controls.name.touched">
                <span *ngIf="menu.controls.name.errors?.required">{{ 'pages.menu.modal.required' | translate }}</span>
                <span *ngIf="menu.controls.name.errors?.minlength">{{ 'pages.menu.modal.min' | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="files">
            <div class="files__item">
              <mat-label class="label">{{ 'pages.menu.modal.upload' | translate }}</mat-label>
              <app-form-file-upload
                appDragAndDropFiles
                formControlName="menuFile"
                [previewUrl]="menuPreviewUrl(i)"
                accept="application/pdf"
              >
                {{ 'formFilUpload.dragAndDrop' | translate }}
              </app-form-file-upload>
            </div>
            <div class="files__item">
              <mat-label class="label">{{ 'pages.menu.modal.icon' | translate }}</mat-label>
              <app-form-file-upload
                appDragAndDropFiles
                formControlName="iconFile"
                [previewUrl]="this.menus[i]?.icon ?? ''"
                accept="image/svg+xml"
              >
                {{ 'formFilUpload.dragAndDrop' | translate }}
              </app-form-file-upload>
            </div>
          </div>
          <mat-slide-toggle
            formControlName="hidden"
            color="primary"
          >
            {{ 'pages.menu.menuContent.hide' | translate }}
          </mat-slide-toggle>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
    <div class="mat-dialog-controls">
      <mat-slide-toggle
        formControlName="happyHours"
        color="primary"
      >
        {{ 'pages.menu.menuContent.happyHours' | translate }}
      </mat-slide-toggle>
      <button
        class="btn add-language-btn"
        [disabled]="isUpdating || menusControls[selected]?.controls?.language?.value === null || formArray.invalid"
        (click)="addTranslate()"
      >
        <mat-icon>add</mat-icon>
        {{ 'pages.menu.modal.addLanguage' | translate }}
      </button>
    </div>

    <div class="mat-dialog-controls">
      <mat-checkbox
        class="example-margin"
        color="primary"
        formControlName="toAll"
      >
        {{ 'pages.chains.modal.addToAllRest' | translate }}
      </mat-checkbox>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.chains.menuContent.restaurants' | translate }}</mat-label>
        <mat-select formControlName="restaurants" multiple>
          <mat-option
            *ngFor="let restaurant of data.restaurants"
            [value]="restaurant.id"
          >
            {{ restaurant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <mat-dialog-actions class="mat-dialog-actions menu">
    <div class="btn__skeleton-loader" *ngIf="isUpdating; else submitBtn">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <ng-template #submitBtn>
      <button
        class="btn btn__primary"
        type="submit"
        [disabled]="formArray.invalid "
        (click)="update()"
      >{{ 'pages.menu.menuContent.update' | translate }}
      </button>
    </ng-template>

      <button
        class="btn btn__warning"
        (click)="delete(data.chainId, data.menusGroup[0].id, 0)"
      >{{ 'pages.menu.actions.delete' | translate }}
      </button>
  </mat-dialog-actions>
</div>
