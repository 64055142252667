 <div class="search">
  <app-search
    (searchValue)="applyFilter($event)"
    [searchStr]="this.searchTerm$.getValue()"
    [placeholder]="'pages.menu.actions.search' | translate"
  ></app-search>
</div>


<table
  mat-table
  [dataSource]="dataSource"
  multiTemplateDataRows
  class="mat-elevation-z2">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'pages.menu.menuContent.menuName' | translate }}</th>
    <td mat-cell *matCellDef="let menu" (click)="toggleRow(menu)">
      <div class="name__box">
        {{menu.name}}
        <svg *ngIf="menu.translation?.data && menu.translation?.data?.length > 0" width="15" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m13.5 1-6 6-6-6" stroke="#55545C" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
  </ng-container>

  <!-- Language Column -->
  <ng-container matColumnDef="language">
    <th mat-header-cell *matHeaderCellDef>Language</th>
    <td mat-cell *matCellDef="let menu">
      <div class="language-icon">
        <svg *ngIf="menu.language === 'fr-CA'" width="21" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#F5F5F5" stroke-width=".5"/>
          <mask id="a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="16">
            <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#fff" stroke-width=".5"/>
          </mask>
          <g mask="url(#a)">
            <path fill="#F44653" d="M13.8.5h6.7v15h-6.7z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M.5 15.5h6.7V.5H.5v15Z" fill="#1035BB"/>
          </g>
        </svg>
        <svg *ngIf="menu.language === 'en-CA'" width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#a)">
            <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#1A47B8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4 4.5H2.2V7l18.4 12.5h2.2V17L4.4 4.5Z" fill="#fff"/>
            <path d="M3 4.5 22.7 18v1.5h-.7L2.1 6V4.5H3Z" fill="#F93939"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8 4.5h2V7S10.1 15.3 4.2 19.5h-2V17L20.8 4.5Z" fill="#fff"/>
            <path d="M22.8 4.5h-.7L2.1 18v1.5H3L23 6V4.5Z" fill="#F93939"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.7 4.5h5.6v4.6h7.5V15h-7.5v4.6H9.7v-4.6H2.2V9h7.5V4.5Z" fill="#fff"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9 4.5H14v5.8h8.7v3.4h-8.7v5.8H11v-5.8H2.2v-3.4h8.7V4.5Z" fill="#F93939"/>
          </g>
          <defs>
            <clipPath id="a">
              <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#fff"/>
            </clipPath>
          </defs>
        </svg>
        <ng-container *ngIf="!isExpanded(menu) && menu.translation?.data?.length">
          <ng-container *ngFor="let menu of menu.translation.data; let i = index">
            <svg *ngIf="menu.language === 'fr-CA'" width="21" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#F5F5F5" stroke-width=".5"/>
              <mask id="a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="16">
                <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#fff" stroke-width=".5"/>
              </mask>
              <g mask="url(#a)">
                <path fill="#F44653" d="M13.8.5h6.7v15h-6.7z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M.5 15.5h6.7V.5H.5v15Z" fill="#1035BB"/>
              </g>
            </svg>
            <svg *ngIf="menu.language === 'en-CA'" width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#a)">
                <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#1A47B8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4 4.5H2.2V7l18.4 12.5h2.2V17L4.4 4.5Z" fill="#fff"/>
                <path d="M3 4.5 22.7 18v1.5h-.7L2.1 6V4.5H3Z" fill="#F93939"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8 4.5h2V7S10.1 15.3 4.2 19.5h-2V17L20.8 4.5Z" fill="#fff"/>
                <path d="M22.8 4.5h-.7L2.1 18v1.5H3L23 6V4.5Z" fill="#F93939"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.7 4.5h5.6v4.6h7.5V15h-7.5v4.6H9.7v-4.6H2.2V9h7.5V4.5Z" fill="#fff"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9 4.5H14v5.8h8.7v3.4h-8.7v5.8H11v-5.8H2.2v-3.4h8.7V4.5Z" fill="#F93939"/>
              </g>
              <defs>
                <clipPath id="a">
                  <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#fff"/>
                </clipPath>
              </defs>
            </svg>
          </ng-container>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <!-- File Column -->
  <ng-container matColumnDef="file">
    <th mat-header-cell *matHeaderCellDef>File</th>
    <td mat-cell *matCellDef="let menu">
      <div class="menu__files">
        <div class="menu__files__page_image">
          <img class="menu__files__page_image__mini" *ngIf="menu.pages && menu.pages.length > 0 else loader" src="{{menu.pages[0]}}" alt="">
          <div class="menu__files__page_image__big" *ngIf="menu.pages && menu.pages.length > 0">
            <div class="box__inside">
              <img src="{{menu.pages[0]}}" alt="">
              <a class="menu__files__download" [href]="menu.file.url" target="_blank" download>
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.7 15.4v4.2a2 2 0 0 1-2.1 2H5a2 2 0 0 1-2.1-2v-4.2m4.1-5.1 5.2 5.1m0 0 5.2-5.1m-5.2 5.1V3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
          <ng-template #loader>
            <mat-progress-spinner
              *ngIf="!menu.processingFailedAt"
              class="spinner"
              [color]="'primary'"
              [mode]="'indeterminate'"
              diameter="50"
            >
            </mat-progress-spinner>
            <div class="processing-error" *ngIf="menu.processingFailedAt">
              <mat-icon class="menu__error-icon">error_outline</mat-icon>
              <span>{{ 'pages.menu.menuContent.processingFailed' | translate }}</span>
            </div>
          </ng-template>
        </div>
        <div *ngIf="menu.file" class="menu__files__pdf">
          <a class="name" [href]="menu.file.url" [matTooltip]="menu.file.name" target="_blank">{{ menu.file.name }}</a>
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Icon Column -->
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef>Icon</th>
    <td mat-cell *matCellDef="let menu">
      <img class="menu__icon__img" *ngIf="menu.icon else noIcon" src="{{ menu.icon }}" alt="">
      <ng-template #noIcon>
        <div class="menu__no_icon">
          <img class="menu__icon__img" src="assets/icons/noIcon.svg" alt="" />
          <span>{{ 'pages.menu.menuContent.noIcon' | translate }}</span>
        </div>
      </ng-template>
    </td>
  </ng-container>

  <!-- Hidden Column -->
  <ng-container matColumnDef="hidden">
    <th mat-header-cell *matHeaderCellDef>{{ 'pages.menu.menuContent.hide' | translate }}</th>
    <td mat-cell *matCellDef="let menu">
      <div class="menu__value">{{ (menu.hidden ? 'common.yes' : 'common.no') | translate }}</div>
    </td>
  </ng-container>

  <!-- Restaurants Column -->
  <ng-container matColumnDef="restaurants">
    <th mat-header-cell *matHeaderCellDef>{{ 'pages.chains.menuContent.restaurants' | translate }}</th>
    <td mat-cell *matCellDef="let menu">
      <div class="menu__value__restaurants" *ngIf="menu.attachedRestaurantIds && menu.attachedRestaurantIds.length > 0">
          <span *ngFor="let restaurantId of menu.attachedRestaurantIds; index as i">
            {{ restaurantNames[restaurantId] }}<span *ngIf="i < menu.attachedRestaurantIds.length - 1">, </span>
          </span>
      </div>
      <div class="menu__value__restaurants" *ngIf="menu.attachedToAll">
        {{ 'pages.chains.menuContent.toAll' | translate }}
      </div>
      <div class="menu__value__restaurants" *ngIf="!menu.attachedRestaurantIds.length && !menu.attachedToAll">
        {{ 'pages.chains.menuContent.notOne' | translate }}
      </div>
    </td>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef [class.edited]="isEdit"></th>
    <td mat-cell *matCellDef="let menu" [class.edited]="isEdit">
      <button class="menu__edit" [class.edited]="isEdit" (click)="openEdit(menu.id)">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.1 3.6a.7.7 0 0 0-.1-1.2l.1 1.2ZM21.6 13a.7.7 0 0 0-1.2-.1l1.2.1Zm-4.3-9.4.5.5-.5-.5Zm1.5-.6v-.6.6ZM21 5.2h.6-.6Zm-.6 1.5-.5-.5.5.5Zm-7.1 7.1v.7l.4-.2-.4-.5Zm-3.1 0h-.6c0 .4.2.7.6.7v-.7Zm0-3-.5-.5-.1.4h.6Zm1.8 9.6A8.4 8.4 0 0 1 3.7 12H2.3c0 5.3 4.4 9.6 9.7 9.6v-1.3ZM3.7 12C3.7 7.7 6.9 4 11 3.6L11 2.4A9.7 9.7 0 0 0 2.3 12h1.4Zm16.7.9c-.5 4.2-4 7.5-8.4 7.5v1.3c5 0 9.1-3.8 9.6-8.7l-1.2-.1Zm-2.6-8.8c.1-.1.3-.3.5-.3l-.5-1.2c-.4.1-.7.3-1 .6l1 .9Zm.5-.3.5-.1V2.3c-.3 0-.7.1-1 .3l.5 1.2Zm.5-.1h.6l.5-1.1c-.3-.2-.7-.3-1-.3v1.4Zm.6 0 .5.4 1-1-1-.5-.5 1.2Zm.5.4c.1.1.3.3.3.5l1.2-.5c-.1-.4-.3-.7-.6-1l-.9 1Zm.3.5.2.6h1.3c0-.4-.1-.8-.3-1.1l-1.2.5Zm.2.6c0 .2 0 .4-.2.5l1.2.5c.2-.3.3-.7.3-1h-1.3Zm-.2.5c0 .2-.2.4-.3.5l1 1 .5-1-1.2-.5Zm-.3.5-7 7.1.8 1 7.1-7.1-.9-1Zm-6.6 7h-3.1v1.3h3v-1.3Zm-2.5.6v-3H9.5v3h1.3Zm-.1-2.6 7-7.1-.9-1-7 7.2.9.9Z" fill="#9D9CA2"/>
        </svg>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td
      class="menu__translations__cell"
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        class="translation-element-detail"
        *ngIf="element.translation?.data?.length"
        [@detailExpand]="isExpanded(element) ? 'expanded' : 'collapsed'"
      >
        <div class="inner-table mat-elevation-z0" *ngIf="isExpanded(element)">
          <table mat-table [dataSource]="element.translation" class="translations">
            <ng-container matColumnDef="name">
              <td mat-cell *matCellDef="let menu"> {{menu.name}} </td>
            </ng-container>

            <!-- Language Column -->
            <ng-container matColumnDef="language">
              <td mat-cell *matCellDef="let menu">
                <svg *ngIf="menu.language === 'fr-CA'" width="21" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#F5F5F5" stroke-width=".5"/>
                  <mask id="a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="16">
                    <rect x=".8" y=".8" width="19.5" height="14.5" rx="1.8" fill="#fff" stroke="#fff" stroke-width=".5"/>
                  </mask>
                  <g mask="url(#a)">
                    <path fill="#F44653" d="M13.8.5h6.7v15h-6.7z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M.5 15.5h6.7V.5H.5v15Z" fill="#1035BB"/>
                  </g>
                </svg>
                <svg *ngIf="menu.language === 'en-CA'" width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#a)">
                    <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#1A47B8"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4 4.5H2.2V7l18.4 12.5h2.2V17L4.4 4.5Z" fill="#fff"/>
                    <path d="M3 4.5 22.7 18v1.5h-.7L2.1 6V4.5H3Z" fill="#F93939"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8 4.5h2V7S10.1 15.3 4.2 19.5h-2V17L20.8 4.5Z" fill="#fff"/>
                    <path d="M22.8 4.5h-.7L2.1 18v1.5H3L23 6V4.5Z" fill="#F93939"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.7 4.5h5.6v4.6h7.5V15h-7.5v4.6H9.7v-4.6H2.2V9h7.5V4.5Z" fill="#fff"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9 4.5H14v5.8h8.7v3.4h-8.7v5.8H11v-5.8H2.2v-3.4h8.7V4.5Z" fill="#F93939"/>
                  </g>
                  <defs>
                    <clipPath id="a">
                      <rect x="2.5" y="4.5" width="20" height="15" rx="2" fill="#fff"/>
                    </clipPath>
                  </defs>
                </svg>
              </td>
            </ng-container>

            <!-- File Column -->
            <ng-container matColumnDef="file">
              <td mat-cell *matCellDef="let menu">
                <div class="menu__files">
                  <div class="menu__files__page_image">
                    <img class="menu__files__page_image__mini" *ngIf="menu.pages && menu.pages.length > 0 else loader" src="{{menu.pages[0]}}" alt="">
                    <div class="menu__files__page_image__big" *ngIf="menu.pages && menu.pages.length > 0">
                      <div class="box__inside">
                        <img src="{{menu.pages[0]}}" alt="">
                        <a class="menu__files__download" [href]="menu.file.url" target="_blank" download>
                          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.7 15.4v4.2a2 2 0 0 1-2.1 2H5a2 2 0 0 1-2.1-2v-4.2m4.1-5.1 5.2 5.1m0 0 5.2-5.1m-5.2 5.1V3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <ng-template #loader>
                      <mat-progress-spinner
                        *ngIf="!menu.processingFailedAt"
                        class="spinner"
                        [color]="'primary'"
                        [mode]="'indeterminate'"
                        diameter="50"
                      >
                      </mat-progress-spinner>
                      <div class="processing-error" *ngIf="menu.processingFailedAt">
                        <mat-icon class="menu__error-icon">error_outline</mat-icon>
                        <span>{{ 'pages.menu.menuContent.processingFailed' | translate }}</span>
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="menu.file" class="menu__files__pdf">
                    <a class="name" [href]="menu.file.url" [matTooltip]="menu.file.name" target="_blank">{{ menu.file.name }}</a>
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- Icon Column -->
            <ng-container matColumnDef="icon">
              <td mat-cell *matCellDef="let menu">
                <div class="menu__icon">
                  <img class="menu__icon__img" *ngIf="menu.icon else noIcon" src="{{ menu.icon }}" alt="">
                  <ng-template #noIcon>
                    <img class="menu__icon__img" src="assets/icons/noIcon.svg" alt="" />
                    <span>{{ 'pages.menu.menuContent.noIcon' | translate }}</span>
                  </ng-template>
                </div>
              </td>
            </ng-container>

            <!-- Hidden Column -->
            <ng-container matColumnDef="hidden">
              <td mat-cell *matCellDef="let menu">
                <div class="menu__value">{{ (menu.hidden ? 'common.yes' : 'common.no') | translate }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="restaurants">
              <td mat-cell *matCellDef="let menu">
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <td mat-cell *matCellDef="let menu" [class.edited]="isEdit"></td>
            </ng-container>


            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns;"
    [class.translation-element-row]="element.translation?.data?.length"
    [class.translation-expanded-row]="isExpanded(element)"
  >
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="translations-row"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="7">No data matching the filter "{{searchTerm$.getValue()}}"</td>
  </tr>
</table>

<mat-paginator
  [pageSizeOptions]="[10, 25, 50, 100]"
  [pageSize]="25"
  aria-label="Select page of menus"
>
</mat-paginator>
