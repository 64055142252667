import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OongaleeIntercom } from './services/oongalee-intercom';
import { catchError, retry } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isLoading = true;

  constructor(
    private translate: TranslateService,
    private oongaleeIntercom: OongaleeIntercom,
  ) {
    this.oongaleeIntercom.init();

    translate.addLangs(['en-CA', 'fr-CA']);
    translate.setDefaultLang('en-CA');

    const lang = localStorage.getItem('lang') || translate.defaultLang;
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', lang);
    }

    this.loadTranslations(lang);
  }

  private loadTranslations(lang: string): void {
    this.translate.getTranslation(lang).pipe(
      retry(3),
      catchError((error) => {
        console.error('Error loading the translation file:', error);
        return of(null);
      })
    ).subscribe((translations) => {
      if (translations) {
        this.translate.use(lang);
        this.isLoading = false;
      } else {
        console.error('Failed to load the translation file after retries');
      }
    });
  }

}
