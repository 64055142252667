import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../../services/admin/admin.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-restaurant',
  templateUrl: './add-restaurant.component.html',
  styleUrls: ['./add-restaurant.component.scss']
})
export class AddRestaurantComponent {

  public loading = false;
  public addRestaurantForm: FormGroup = this.generateForm();
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { chainId: number },
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private admin: AdminService,
  ) { }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      language: ['en-CA', [Validators.required]],
      province: [null],
      dwallGroupId: [null]
    });
  }

  addRestaurant(form: FormGroup): void {
    this.loading = true;
    const newRestaurantInfo = {
      chainId: this.data.chainId,
      name: form.value.name,
      email: form.value.email,
      password: form.value.password,
      language: form.value.language,
      province: form.value.province,
      dwallGroupId: form.value.dwallGroupId,
    };

    this.admin.addRestaurant(newRestaurantInfo).subscribe(
      (data) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }
}
