import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { forkJoin, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-add-chain-menu',
  templateUrl: './add-chain-menu.component.html',
  styleUrls: ['./add-chain-menu.component.scss']
})
export class AddChainMenuComponent {

  public isLoading = false;
  public selected = 0;
  public languages = [
    { value: 'en-CA', viewValue: 'English' },
    { value: 'fr-CA', viewValue: 'French' },
    { value: null, viewValue: 'Not Set' },
  ];
  public form = new FormGroup({
    happyHours: new FormControl(this.data.happyHours),
    restaurants: new FormControl({ value: null, disabled: true }),
    toAll: new FormControl(true),
    menusFormArray: new FormArray([
      new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        language: new FormControl(null),
        hidden: new FormControl(false),
        menuFile: new FormControl(null, [Validators.required]),
        iconFile: new FormControl(null),
      }),
    ]),
  });
  public menusFormArray = this.form.get('menusFormArray') as FormArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chainId: number,
      happyHours: boolean,
      hidden: boolean,
      allRestaurants: IRestaurantInfo[]
    },
    private menuState: MenuStoreService,
    private dialog: MatDialog,
  ) {
    this.form.get('toAll')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((value: boolean) => {
      if (value) {
        this.form.get('restaurants')?.reset({
          value: [],
          disabled: true,
        });
      } else {
        this.form.get('restaurants')?.reset({
          value: null,
          disabled: false,
        });
      }
    });
  }

  get menusControls(): FormGroup[] {
    return (this.form.get('menusFormArray') as FormArray).controls as FormGroup[];
  }

  public isLanguageAvailable(language: string | null, currentIndex: number): boolean {
    const selectedLanguages = this.menusControls
      .map((menu, index) => index !== currentIndex ? menu.controls.language.value : null)
      .filter(lang => lang !== null);

    return !selectedLanguages.includes(language) && !(this.menusControls.length > 1 && language === null);
  }

  public addTranslate(): void {
    (this.form.get('menusFormArray') as FormArray).push(new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      language: new FormControl(null, [Validators.required]),
      hidden: new FormControl(false),
      menuFile: new FormControl(null, [Validators.required]),
      iconFile: new FormControl(null)
    }));

    this.selected = this.menusFormArray.length - 1;
  }

  public removeTranslate(index: number): void {
    this.menusFormArray.removeAt(index);
    this.selected = index;
  }

  public onSubmit(): void {
    this.isLoading = true;

    const [menuOriginalData, ...menuTranslatedData] = this.menusControls;

    const addChainMenu = (menuData: any, originalId: number | null) =>
      this.menuState.addChainMenu(
        this.data.chainId,
        menuData.value.name,
        menuData.value.language,
        this.form.value.happyHours,
        menuData.hidden,
        menuData.value.menuFile,
        this.form.value.toAll ? [] : this.form.value.restaurants,
        originalId,
        menuData.value.iconFile,
      );

    addChainMenu(menuOriginalData, null).pipe(
      switchMap((chainMenu) => {
        if (menuTranslatedData.length > 0) {
          if (menuTranslatedData.length > 0) {
            return forkJoin(menuTranslatedData.map((translate) => {
              addChainMenu(translate, chainMenu.id);
            }));
          }
        }
        return of(chainMenu);
      }),
      untilDestroyed(this)
    ).subscribe({
      next: () => {
        this.isLoading = false;
        this.dialog.closeAll();
      },
      error: () => {
        this.isLoading = false;
        this.dialog.closeAll();
      },
    });
  }
}
