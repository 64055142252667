import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @Output() searchValue: EventEmitter<string> = new EventEmitter<string>();
  @Input() set searchStr(str: string) {
    this.search.setValue({searchInput: str});
  }
  @Input() placeholder = 'Search';
  @Input() set disabled(condition: boolean ) {
    this.isDisabled = condition;
    if (condition) {
      this.search.controls.searchInput.disable({emitEvent: false});
    } else {
      this.search.controls.searchInput.enable({emitEvent: false});
    }
  }
  public isDisabled = false;
  public search = new FormGroup({
    searchInput: new FormControl('')
  });
  public icons = {
    search: faSearch,
    clear: faTimesCircle
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.searchSubscribe();
  }

  private searchSubscribe(): void {
    this.search.valueChanges
      .pipe(
        untilDestroyed(this),
        pluck('searchInput'),
        debounceTime(500),
        map(val => val ? val.trim() : val),
        distinctUntilChanged(),
      ).subscribe( str => {
        this.searchValue.emit(str);
        if (str.length > 0) {
          this.router.navigate([], {
            relativeTo: this.activateRoute,
            queryParams: { s: str },
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([]);
        }
      });
  }

  get searchInput(): string {
    return this.search.controls.searchInput.value;
  }

  public clearSearch(): void {
    this.search.setValue({searchInput: ''});
  }
}
