import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Period, NewPeriod } from '../../../interfaces/period';
import { BehaviorSubject } from 'rxjs';
import {
  dayEndValidator,
  dayStartValidator,
  timeEndValidator,
  timeStartValidator,
} from '../../../shared/custom-validators';
import { ConfirmationDialogComponent } from '../../modals/confirm-dialog/confirmation-dialog.component';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit, OnChanges {

  @Input()
  public happyHourPeriod!: Period;
  @Input()
  public periods!: Period[];
  @Input()
  public editable!: boolean;
  @Input()
  public periodType!: 'happy' | 'workingTime';

  @Output()
  newPeriod = new EventEmitter<NewPeriod>();
  @Output()
  cancel = new EventEmitter<boolean>();
  @Output()
  delete = new EventEmitter<number>();
  @Output()
  update = new EventEmitter<Period>();

  public newHappyHourPeriod!: FormGroup;
  public weekdays: {name: string, index: number}[] = [];
  public isEdit = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.weekdays = [
      { index: 1, name: 'Monday' },
      { index: 2, name: 'Tuesday' },
      { index: 3, name: 'Wednesday' },
      { index: 4, name: 'Thursday' },
      { index: 5, name: 'Friday' },
      { index: 6, name: 'Saturday' },
      { index: 7, name: 'Sunday' }
    ];
    this.newHappyHourPeriod = this.generateForm();
    this.newHappyHourPeriod.patchValue(this.happyHourPeriod);

    this.isEdit.pipe(
      untilDestroyed(this)
    ).subscribe((value) => {
      if (value && this.happyHourPeriod) {
        this.newHappyHourPeriod.enable();
      } else {
        this.newHappyHourPeriod.disable();
      }
    });

    this.setupDateTimeFields();
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      startDay: new FormControl({value: this.happyHourPeriod?.startDay ?? null, disabled: true}, [
        Validators.required,
        dayStartValidator(this.periods, this.happyHourPeriod?.id ?? null)
      ]),
      startTime: new FormControl({value: this.happyHourPeriod?.startTime ?? null, disabled: true},  [
        Validators.required,
        timeStartValidator(this.periods, this.happyHourPeriod?.id ?? null)
      ]),
      endDay: new FormControl({value: this.happyHourPeriod?.endDay ?? null, disabled: true}, [
        Validators.required,
        dayEndValidator(this.periods, this.happyHourPeriod?.id ?? null)
      ]),
      endTime: new FormControl({value: this.happyHourPeriod?.endTime ?? null, disabled: true}, [
        Validators.required,
        timeEndValidator(this.periods, this.happyHourPeriod?.id ?? null)
      ]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.periods) {
      this.updateValidators();
    }
  }

  private setupDateTimeFields(): void {
    const startDayControl = this.newHappyHourPeriod.get('startDay');
    const startTimeControl = this.newHappyHourPeriod.get('startTime');
    const endDayControl = this.newHappyHourPeriod.get('endDay');
    const endTimeControl = this.newHappyHourPeriod.get('endTime');

    if (!this.happyHourPeriod) {
      startDayControl?.enable();
    }

    startDayControl?.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      if (startDayControl?.valid && (this.isEdit.getValue() || !this.happyHourPeriod)) {
        startTimeControl?.enable();
      }
    });

    startTimeControl?.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      if (startTimeControl?.valid && (this.isEdit.getValue() || !this.happyHourPeriod)) {
        endDayControl?.enable();
      }
    });

    endDayControl?.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      if (endDayControl?.valid && (this.isEdit.getValue() || !this.happyHourPeriod)) {
        endTimeControl?.enable();
      }
    });
  }

  public createPeriod(newPeriod: NewPeriod): void {
    this.newPeriod.emit(newPeriod);
    this.newHappyHourPeriod.reset();
  }

  public editPeriod(id: number): void {

    if (this.isEdit.getValue() && id === this.happyHourPeriod.id) {
      const isFormChange = !this.areEqual(this.happyHourPeriod, this.newHappyHourPeriod.value);

      if (isFormChange) {
        const title = this.translate.instant('period.unsavedPeriodTitle');
        const message = this.translate.instant('period.unsavedChanges');
        const confirmationModal = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            message,
            title
          },
          panelClass: 'confirmation-modal'
        });

        confirmationModal.afterClosed().pipe(
          filter(Boolean),
        ).subscribe((): void => {
          this.newHappyHourPeriod.patchValue(this.happyHourPeriod);
          this.isEdit.next(false);
        });
      } else {
        this.isEdit.next(false);
      }
    } else {
      this.isEdit.next(true);
    }
  }

  public deletePeriod(id: number): void {
    const title = this.translate.instant('period.' + this.periodType + '.deletePeriodTitle');
    const message = this.translate.instant('period.' + this.periodType + '.deletePeriod');

    const confirmationModal = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.delete.emit(id);
    });
  }

  public updatePeriod(id: number): void {
    this.update.emit({id, ...this.newHappyHourPeriod.value});
    this.isEdit.next(false);
  }

  public cancelCreation(): void {
    this.cancel.emit(false);
  }

  private areEqual = (a: Period, b: NewPeriod): boolean => {
    return (
      a.startDay === b.startDay &&
      a.startTime === b.startTime &&
      a.endDay === b.endDay &&
      a.endTime === b.endTime
    );
  }

  private updateValidators(): void {
    if (!this.newHappyHourPeriod) {
      this.newHappyHourPeriod = this.generateForm();
      return;
    }


    this.newHappyHourPeriod.get('startDay')?.setValidators([
      Validators.required,
      dayStartValidator(this.periods, this.happyHourPeriod?.id ?? null),
    ]);

    this.newHappyHourPeriod.get('startTime')?.setValidators([
      Validators.required,
      timeStartValidator(this.periods, this.happyHourPeriod?.id ?? null),
    ]);

    this.newHappyHourPeriod.get('endDay')?.setValidators([
      Validators.required,
      dayEndValidator(this.periods, this.happyHourPeriod?.id ?? null),
    ]);

    this.newHappyHourPeriod.get('endTime')?.setValidators([
      Validators.required,
      timeEndValidator(this.periods, this.happyHourPeriod?.id ?? null),
    ]);

    this.newHappyHourPeriod.updateValueAndValidity();
  }
}
