<div *ngIf="lighterColor" class="lighter" [style.--lighter-color]="lighterColor"></div>
<div class="screen" [class.initialized]="initialized">
  <iframe
    #iframe
    [src]="this.url"
    title="Preview"
    (load)="onIframeLoad()"
  ></iframe>
  <img class="logo" src="/assets/icons/oongalee-logo.png" alt="Logo" />
</div>
