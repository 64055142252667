import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { WidgetUIConfiguration } from '../../../interfaces/widget-ui-config';

@UntilDestroy()
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent {

  @Input()
  public url: SafeUrl | null = null;

  @Input()
  public previewConfig = new BehaviorSubject<WidgetUIConfiguration | null>(null);

  @ViewChild('iframe')
  iframe: ElementRef<HTMLIFrameElement> | undefined;

  public iframeLoaded = false;
  public lighterColor: string | null = null;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public onIframeLoad(): void {
    const channel = new MessageChannel();
    this.iframe?.nativeElement.contentWindow?.postMessage('dwall-handshake', '*', [channel.port2]);

    channel.port1.onmessage = (e) => {
      const message = JSON.parse(e.data);
      switch (message.method) {
        case 'init':
          this.iframeLoaded = true;
          break;
        case 'ambient_light.on':
          const color = message.data.color;
          this.lighterColor = `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`;
          this.changeDetectorRef.detectChanges();
          break;
        case 'ambient_light.off':
          this.lighterColor = null;
          this.changeDetectorRef.detectChanges();
          break;
      }
    };

    const sendMessage = (event: string, data: any) => {
      channel.port1.postMessage({
        type: 'dwall-events',
        version: '0.0.1',
        event,
        data,
      });
    };

    sendMessage('demo.mode', true);

    setTimeout(() => {
      sendMessage('demo.events', this.demoMessageLoginLoginSuccessful());
      sendMessage('demo.events', this.demoMessageTableInfo(this.previewConfig.getValue() as WidgetUIConfiguration));
    }, 1000);

    this.previewConfig.pipe(
      debounceTime(500),
      untilDestroyed(this),
    ).subscribe((widgetUIConfig) => {
      sendMessage('demo.events', this.demoMessageTableInfo(widgetUIConfig as WidgetUIConfiguration));
    });
  }

  private demoMessageLoginLoginSuccessful(): any {
    return { type: 'loginSuccessful', data: null };
  }

  private demoMessageTableInfo(widgetUIConfig: WidgetUIConfiguration): any {
    return {
      type: 'tableInfo',
      data: {
        tableId: 1,
        tableName: 'Demo Table',
        adRunEvery: null,
        adVastTagUrl: null,
        adVistarMediaApiKey: null,
        adVistarMediaNetworkId: null,
        adVistarMediaVenueId: null,
        invoiceQRCodeUrl: `/app/demo/qr-code.jpg`,
        language: 'en-CA',
        wifiName: 'demo_5G',
        wifiPassword: 'demo1234',
        widgetUIConfig,
        menus: [
          {
            id: 1,
            name: 'Demo Menu 1',
            happyHours: false,
            hidden: false,
            ownerType: 'restaurant',
            createdAt: '2021-05-20T16:00:00.000Z',
            updatedAt: '2021-05-20T16:00:00.000Z',
            pages: [`/app/demo/menu.jpg`],
          },
          {
            id: 2,
            name: 'Demo Menu 2',
            happyHours: false,
            hidden: false,
            ownerType: 'restaurant',
            createdAt: '2021-05-20T16:00:00.000Z',
            updatedAt: '2021-05-20T16:00:00.000Z',
            pages: [`/app/demo/menu.jpg`],
          },
          {
            id: 3,
            name: 'Demo Happy Hour Menu 1',
            happyHours: true,
            hidden: false,
            ownerType: 'restaurant',
            createdAt: '2021-05-20T16:00:00.000Z',
            updatedAt: '2021-05-20T16:00:00.000Z',
            pages: [`/app/demo/menu.jpg`],
          },
          {
            id: 4,
            name: 'Demo Happy Hour Menu 2',
            happyHours: true,
            hidden: false,
            ownerType: 'restaurant',
            createdAt: '2021-05-20T16:00:00.000Z',
            updatedAt: '2021-05-20T16:00:00.000Z',
            pages: [`/app/demo/menu.jpg`],
          },
        ],
      },
    };
  }

}
