<div class="network-offline" *ngIf="!isOnline || (isOnline && !isWSConnected)">
  <div *ngIf="!isOnline" [innerHTML]="'offline' | translate"></div>
  <div *ngIf="isOnline && !isWSConnected" [innerHTML]="'serviceConnection' | translate"></div>
</div>

<app-header
  [links]="menuLinks"
  [backLink]="backLink"
  [restaurantName]="restaurant.name"
></app-header>
<router-outlet></router-outlet>


<div class="language">
  <select class="form-control" name="selectLang" (change)="handlerChangeLanguage($event)">
    <option *ngFor="let lang of availableLanguages" [value]="lang" [selected]="lang === currentLanguage">
      {{ 'languages.' + lang + '.label' | translate }}
    </option>
  </select>
</div>
